<template>
  <div style="text-align: center;">

    <section id="index" class="container">
      <header class="comm-title">
        <h2 class="fl tac">
          <span class="c-333">已开发票</span>
        </h2>
      </header>
      <el-table :data="list" border style="width: 100%">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="number" label="发票号" width="230"></el-table-column>
        <el-table-column prop="price" label="金额"></el-table-column>
        <el-table-column prop="kuaidinumber" label="快递单号"></el-table-column>
        <el-table-column prop="status" label="状态"></el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <!-- <el-button v-if="scope.row.status === '已申请'" type="text" @click="download(scope.row.provideourl)">查看
            </el-button> -->
            <el-button v-if="scope.row.status === '已开具'" type="text" >查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>

  </div>
</template>

<script>

import receiptApi from "../api/receipt";

export default {
  data() {
    return {
      list: [], //订单列表
      recpType:'',
       options: [{
          value: '增值税普通发票',
          label: '增值税普通发票'
        }, {
          value: '增值税专用发票',
          label: '增值税专用发票'
        }],
    };
  },

  created() {
    //#region 用户检查
    var userName = localStorage.getItem("userName");
    if (userName == "" || userName == null) {
      this.$router.push({ path: "/login" });
    }
    else {
      this.showReceiptList()
    }
    //#endregion
  },

  methods: {
    showReceiptList() {
      var userId = localStorage.getItem("userId");
      receiptApi.list(userId).then((response) => {
        this.list = response.data.list;
      });
    }
  }
};
</script>

<style >
  .addreceipt {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  }
  .login-container {
  border-radius: 10px;
  margin: 0px auto;
  width: 350px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}
</style>