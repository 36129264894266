var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"container",attrs:{"id":"userBalance"}},[_c('header',{staticClass:"comm-title"},[_c('h2',[_c('span',{staticClass:"c-333"},[_vm._v("您是 "+_vm._s(_vm.vipname)+" 用户")]),_vm._v("    到期时间"+_vm._s(_vm.vipendtime)+"    "),_c('span',{staticClass:"c-333"},[_vm._v("金币数 "+_vm._s(_vm.balance / 10)+" 个")])])])]),_c('section',[_vm._m(0),_c('ul',[_c('table',{staticStyle:{"text-align":"center","width":"80%"},attrs:{"border":"1px"}},[_c('tr',[_c('td',[_vm._v("特权对比")]),_vm._l((_vm.vipProductList),function(product){return _c('td',{key:product.id},[_c('a',{class:[
                            'orderBtn',
                            { current: _vm.payOrder.productId === product.id },
                        ],attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.selectItem(product.id)}}},[_vm._v(" "+_vm._s(product.title)+" ¥"+_vm._s(product.price / 100)+" ")])])})],2),_c('tr',{staticClass:"trRow"},[_c('td'),_c('td',[_c('el-button',{staticClass:"payBtn",staticStyle:{"height":"44px","font-size":"18px"},attrs:{"disabled":_vm.payVIPBtnDisabled,"type":"warning","round":""},on:{"click":function($event){return _vm.toPayVIP(9)}}},[_vm._v(" 立即开通 ")])],1),_c('td',[_c('el-button',{staticClass:"payBtn",staticStyle:{"height":"44px","font-size":"18px"},attrs:{"disabled":_vm.paySVIPBtnDisabled,"type":"warning","round":""},on:{"click":function($event){return _vm.toPayVIP(10)}}},[_vm._v(" 立即开通 ")])],1)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])])]),_c('div',[_c('el-dialog',{attrs:{"visible":_vm.codeDialogVisible,"show-close":false,"width":"350px","center":""},on:{"update:visible":function($event){_vm.codeDialogVisible=$event},"close":_vm.closeDialog}},[_c('qriously',{attrs:{"value":_vm.codeUrl,"size":300}}),_vm._v(" 使用微信扫码支付 ")],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"comm-title"},[_c('h2',{staticClass:"fl tac"},[_c('span',{staticClass:"c-333"},[_vm._v("VIP申请")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('br')]),_c('td'),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"trRow"},[_c('td',[_vm._v("VIP活动")]),_c('td',[_vm._v("赠送1000金币")]),_c('td',[_vm._v("赠送5000金币")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('br')]),_c('td'),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"trRow"},[_c('td',[_vm._v("授权期限")]),_c('td',[_vm._v("永久授权")]),_c('td',[_vm._v("永久授权")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('br')]),_c('td'),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"trRow"},[_c('td',{attrs:{"rowspan":"3"}},[_vm._v("下载数量")]),_c('td',[_vm._v("10个视频/天")]),_c('td',[_vm._v("30个视频/天")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"trRow"},[_c('td',[_vm._v("20个音频/天")]),_c('td',[_vm._v("50个视频/天")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"trRow"},[_c('td',[_vm._v("50个音频/天")]),_c('td',[_vm._v("100个视频/天")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('br')]),_c('td'),_c('td')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"trRow"},[_c('td',[_vm._v("下载大小")]),_c('td',[_vm._v("1GB/天")]),_c('td',[_vm._v("10GB/天")])])
}]

export { render, staticRenderFns }