// axios 发送ajax请求
import request from '@/utils/request'

export default {

  //查询登录信息
  login(userName, passWord) {
    return request({
      url: '/api/user/query-user/' + userName + '/' + passWord,
      method: 'post'
    })
  },

  //用户注册
  register(userName, nickName, passWord) {
    return request({
      url: '/api/user/create-user/' + userName + '/' + nickName + '/' + passWord,
      method: 'post'
    })
  },

  //根据用户ID查询用户信息
  queryUserByID(userID) {
    return request({
      url: '/api/user/query-user-by-id/' + userID,
      method: 'post'
    })
  },


  //根据微信ID查询用户信息
  queryUserByWxID(wxId) {
    return request({
      url: '/api/user/query-user-by-wx-id/' + wxId,
      method: 'post'
    })
  },

  updateRestTime(userid, videotime, audiotime, imgtime) {
    return request({
      url: '/api/user/updateRestTime/' + userid + '/' + videotime + '/' + audiotime + '/' + imgtime,
      method: 'post'
    })
  }
}