<template>
  <div class="bg-fa of">
    <section id="index" class="container">
      <header class="comm-title">
        <h2 class="fl tac">
          <span class="c-333">用户统计</span>
        </h2>
      </header>


      <br />
      <el-table :data="list.slice((currentPage - 1) * PageSize, currentPage * PageSize)" border style="width: 95%">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="userName" label="用户姓名" width="230"></el-table-column>
        <el-table-column prop="userDept" label="用户部门" width="230"></el-table-column>
        <el-table-column prop="macAddress" label="用户MAC"></el-table-column>
        <el-table-column prop="totalUsageTime" label="使用时长">
          <template slot-scope="scope">
            {{ scope.row.totalUsageTime }} hour(s)
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align:center">
        <span>&nbsp;</span>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
        </el-pagination>
      </div>



      <el-dialog title="申诉" :visible.sync="dialogVisible" width="30%">
        <el-form>

          <el-form-item label="申诉类型">
            <el-select v-model="comType" placeholder="申诉类型" style="width:100%">
              <el-option v-for="item in complainOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="详细说明">
            <el-input type="text" v-model="complainReason" placeholder="申诉原因详细说明" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="submitComplain()">提交</el-button>
          </el-form-item>
        </el-form>

      </el-dialog>


      <div>
        <h2>
          本周活跃用户：{{ list.length }} 人
        </h2>
      </div>

      <br />

      <div>
        <h2>
          新增用户
        </h2>
      </div>



      <div>
        <h2>
          版本分布
        </h2>
      </div>

      <div>
        <h2>
          功能使用频率分布
        </h2>
      </div>





    </section>



  </div>
</template>
  
<script>

import statisticsApi from "../api/statistics";

export default {
  data() {
    return {
      list: [], //订单列表
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      PageSize: 20,
      comType: '',
      complainOptions: [{
        value: '视频不能下载',
        label: '视频不能下载'
      }, {
        value: '效果不满意',
        label: '效果不满意'
      }, {
        value: '扣款问题',
        label: '扣款问题'
      }, {
        value: '其他',
        label: '其他'
      }],
      complainReason: '',
      dialogVisible: false,
      complainid: '',
    };
  },

  created() {
    this.showProjectList()
    //   //#region 用户检查
    //   var userName = localStorage.getItem("userName");
    //   if (userName == "" || userName == null) {
    //     this.$router.push({ path: "/login" });
    //   }
    //   else {

    //   }
    //#endregion
  },
  mounted() {
  },

  methods: {
    //显示订单列表
    showProjectList() {
      // var userId = localStorage.getItem("userId");
      statisticsApi.list().then((response) => {
        this.list = response;
        console.log(response)
        this.totalCount = this.list.length;
      });
    },


    handleSizeChange(val) {
      // 改变每页显示的条数 
      this.PageSize = val
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.currentPage = 1
    },
    // 显示第几页
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val
    },
  }
};
</script>