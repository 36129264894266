// 创建应用程序的路由器
import Vue from 'vue'
import VueRouter from 'vue-router'
// 此时就可以在Vue实例中配置路由器了
Vue.use(VueRouter)

// 引入组件
import Index from '../views/index'
import Orders from '../views/Orders'
import Download from '../views/Download'
import Success from '../views/Success'
import Login from '../views/Login'
import Register from '../views/Register'
import VideoProcess from '../views/videoProcess'
import News from '../views/News'
import Help from '../views/Help'
import ProjectPay from '../views/projectPay'
import Project from '../views/Project'
import ProjectSuccess from '../views/ProjectSuccess'
import PaymentInfo from '../views/PaymentInfo'
import User from '../views/User'
import About from '../views/About'
import VResource from '../views/VResource'
import VResourceSearch from '../views/VResourceSearch'
import Soft from '../views/Soft'
import VResourceItem from '../views/VResourceItem'
import VResourceAudioItem from '../views/VResourceAudioItem'
import VResourcePicItem from '../views/VResourcePicItem'
import AIImage from '../views/AI/AIImage'
import removePen from '../views/AI/removePen'
import personMatting from '../views/AI/personMatting'
import personMattingFree from '../views/AI/personMattingFree'
import AIVideo from '../views/AIVideo/AIVideo'
import AIPic from '../views/AIPic/AIImage'
import AIAudio from '../views/AIAudio/AIAudio'
import Statistics from '../views/Statistics.vue'

// 创建并暴露一个路由器
export default new VueRouter({
    routes:[
        {
            path: '/',
            component: Index
        },
        {
            path: '/orders',
            component: Orders
        },
        {
            path: '/download',
            component: Download
        },
        {
            path: '/success',
            component: Success
        },
        {
            path:'/login',
            component: Login
        },
        {
            path:'/register',
            component:Register
        },
        {
            path:'/video',
            component:VideoProcess
        },
        {
            path:'/news',
            component:News
        },
        {
            path:'/Help',
            component:Help
        },
        {
            path:'/User',
            component:User
        },
        {
            path:'/projectPay',
            component:ProjectPay
        },
        {
            path:'/project',
            component:Project
        },{
            path:'/projectSuccess',
            component:ProjectSuccess
        },{
            path:'/PaymentInfo',
            component:PaymentInfo
        },
        {
            path:'/About',
            component:About
        },
        {
            path:'/VResource',
            component:VResource
        },
        {
            path:'/VResourceSearch',
            component:VResourceSearch
        },
        {
            path:'/Soft',
            component:Soft
        },
        {
            path:'/VResourceItem',
            component:VResourceItem
        },
        {
            path:'/VResourceAudioItem',
            component:VResourceAudioItem
        },
        {
            path:'/VResourcePicItem',
            component:VResourcePicItem
        },
        {
            path:'/AIImage',
            component:AIImage
        },
        {
            path:'/removePen',
            component:removePen
        },
        {
            path:'/personMatting',
            component:personMatting
        },
        {
            path:'/personMattingFree',
            component:personMattingFree
        },
        {
            path:'/AIVideo',
            component:AIVideo
        },
        {
            path:'/AIPic',
            component:AIPic
        },
        {
            path:'/AIAudio',
            component:AIAudio
        },
        {
            path:'/Statistics',
            component:Statistics
        },
        
        
    ]
})