<template>
  <div>
    
    <section id="index" class="container">
      <header class="comm-title">
        <h2 class="fl tac">
          <span class="c-333">交易列表</span>
        </h2>
      </header>
      <el-table :data="list.slice((currentPage-1)*PageSize,currentPage*PageSize)" border style="width: 100%">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="orderNo" label="交易编号" width="230"></el-table-column>
        <el-table-column prop="paymentType" label="交易类型 ">
          
        </el-table-column>
        <el-table-column prop="tradeType" label="交易项目 ">

        </el-table-column>
        <el-table-column prop="payerTotal" label="项目费用">
          <template slot-scope="scope">
            {{ scope.row.payerTotal / 10 }} 金币
          </template>
        </el-table-column>
      </el-table>

      <div  style="text-align:center">
        <span>&nbsp;</span>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
             :current-page="currentPage" 
             :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
             :total="totalCount">
       </el-pagination>
</div>

    </section>

  </div>
</template>

<script>
import paymentInfoApi from "../api/paymentinfo"
export default {
  data() {
    return {
      list: [], //订单列表
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:1,
      // 默认每页显示的条数（可修改）
      PageSize:20,
    };
  },
  created() {
    var userName = localStorage.getItem("userName");
    if (userName == "" || userName == null) {
      this.$router.push({ path: "/login" });
    }
    else {
      this.showOrderList()
    }
  },

  methods: {
    //显示订单列表
    showOrderList() {
      var userId = localStorage.getItem("userId");
      paymentInfoApi.listByUser(userId).then((response) => {
        this.list = response.data.list;
        this.totalCount = this.list.length;
      });
    },
    handleSizeChange(val) {
      // 改变每页显示的条数 
      this.PageSize=val
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.currentPage=1
    },
     // 显示第几页
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage=val
    },
  }
};
</script>