<template>
  <div>

    <section id="index" class="container">
      <el-tabs v-model="activeName" type="card" tab-position="left" style="height: 1000px;">
        <el-tab-pane label="账户信息" name="first">
          <!-- <section id="userBalance" class="container">
            <header class="comm-title">
              <h2>
                <span class="c-333">您是 {{ vipname }} 用户</span>
              </h2>
              <br />
              <h2>
                <span class="c-333">您的余额￥ {{ balance / 100 }} 元</span>
              </h2>
            </header>
          </section>
          <section>
            <header class="comm-title">
              <h2 class="fl tac">
                <span class="c-333">VIP申请</span>
              </h2>
            </header>
            <ul>
              <table border="1px" style="text-align: center;width: 60%;">
                <tr>
                  <td>特权对比</td>
                  <td v-for="product in vipProductList" :key="product.id">
                    <a :class="[
                      'orderBtn',
                      { current: payOrder.productId === product.id },
                    ]" @click="selectItem(product.id)" href="javascript:void(0);">
                      {{ product.title }}

                      ¥{{ product.price / 100 }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td><br /></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td></td>
                  <td><el-button :disabled="payBtnDisabled" type="warning" round style="height: 44px; font-size: 18px"
                      @click="toPayVIP(9)">
                      立即开通
                    </el-button></td>
                  <td><el-button :disabled="payBtnDisabled" type="warning" round style="height: 44px; font-size: 18px"
                      @click="toPayVIP(10)">
                      立即开通
                    </el-button></td>
                </tr>
                <tr>
                  <td><br /></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td>授权期限</td>
                  <td>永久授权</td>
                  <td>永久授权</td>
                </tr>
                <tr>
                  <td><br /></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td>下载数量</td>
                  <td>5个视频/天</td>
                  <td>100个视频/天</td>
                </tr>
                <tr>
                  <td><br /></td>
                  <td></td>
                  <td></td>

                </tr>
                <tr>
                  <td>下载大小</td>
                  <td>1GB/天</td>
                  <td>10GB/天</td>
                </tr>
              </table>
            </ul>
          </section> -->
          <UserInfo></UserInfo>
        </el-tab-pane>

        <el-tab-pane label="余额充值" name="third">
          <section id="index" class="container">
            <header class="comm-title">
              <h2>
                <span>充值费用</span>
              </h2>
            </header>
            <ul>
              <li class="fee-item" v-for="product in chargeProductList" :key="product.id">
                <a :class="[
                  'orderBtn',
                  { current: payOrder.productId === product.id },
                ]" @click="selectItem(product.id)" href="javascript:void(0);">
                  {{ product.title }}
                  {{ product.price / 10 }}金币
                </a>
              </li>
            </ul>

            <div class="PaymentChannel_payment-channel-panel">
              <h3 class="PaymentChannel_title">支付方式</h3>
              <div class="PaymentChannel_channel-options">
                <!-- 选择微信 -->
                <div :class="[
                  'ChannelOption_payment-channel-option',
                  { current: payOrder.payType === 'wxpay' },
                ]" @click="selectPayType('wxpay')">
                  <div class="ChannelOption_channel-icon">
                    <img src="../assets/img/wxpay.png" class="ChannelOption_icon" />
                  </div>
                  <div class="ChannelOption_channel-info">
                    <div class="ChannelOption_channel-label">
                      <div class="ChannelOption_label">微信支付</div>
                      <div class="ChannelOption_sub-label"></div>
                      <div class="ChannelOption_check-option"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="payButtom">
              <el-button class="upload-demo-btn" :disabled="payBtnDisabled" type="warning" round
                style="width: 180px; height: 44px; font-size: 18px" @click="toPay()">
                确认支付
              </el-button>
            </div>
          </section>

          <el-dialog :visible.sync="codeDialogVisible" :show-close="false" @close="closeDialog" width="350px" center>
            <qriously :value="codeUrl" :size="300" />
            使用微信扫码支付
          </el-dialog>

        </el-tab-pane>

        <el-tab-pane label="查看项目" name="fourth">
          <Project></Project>
        </el-tab-pane>

        <el-tab-pane label="发票管理" name="second">
          <el-tabs v-model="activefpName" type="card">
            <el-tab-pane label="开发票" name="fpfirst">
              <ReceiptAdd></ReceiptAdd>
            </el-tab-pane>
            <el-tab-pane label="已开发票" name="fpsecond">
              <ReceiptList></ReceiptList>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>



        <el-tab-pane label="交易记录" name="fifth">
          <PaymentInfo></PaymentInfo>
        </el-tab-pane>


        <el-dialog :visible.sync="codeDialogVisible" :show-close="false" @close="closeDialog" width="350px" center>
          <qriously :value="codeUrl" :size="300" />
          使用微信扫码支付
        </el-dialog>

      </el-tabs>
    </section>

  </div>
</template>

<script>

import productApi from "../api/product";
import wxPayApi from "../api/wxPay";
import orderInfoApi from "../api/orderInfo";
// import userApi from '../api/user';

import ReceiptAdd from './ReceiptAdd.vue';
import ReceiptList from './ReceiptList.vue';
import Project from './Project.vue';
import PaymentInfo from './PaymentInfo.vue'
import UserInfo  from './User/UserInfo.vue'


export default {
  components: {
    ReceiptAdd,
    ReceiptList,
    Project,
    PaymentInfo,
    UserInfo
  },

  data() {
    return {
      payBtnDisabled: false, //确认支付按钮是否禁用
      codeDialogVisible: false, //微信支付二维码弹窗
      chargeProductList: [], //商品列表
      vipProductList: [],
      payOrder: {
        //订单信息
        productId: "", //商品id
        payType: "wxpay", //支付方式
        userId: "",
      },
      codeUrl: "", // 二维码
      orderNo: "", //订单号
      timer: null, // 定时器
      balance: "",
      vipname: "",
      activeName: 'first',
      activefpName: 'fpfirst'
    };
  },
  //页面加载时执行
  created() {
    //#region 用户检查
    var userName = localStorage.getItem("userName");
    if (userName == "" || userName == null || userName == "null") {
      this.$router.push({ path: "/login" });
    }
    else {
      //获取商品列表
      productApi.list().then((response) => {
        this.productList = response.data.productList;


        this.chargeProductList = this.productList.filter((item) => {
          return item.producttype == '充值'
        })
        this.payOrder.productId = this.chargeProductList[0].icharged;


        this.vipProductList = this.productList.filter((item) => {
          return item.producttype == 'SAASVIP'
        })

        this.payOrder.userId = localStorage.getItem("userId");

      });
      // this.queryUserInfo()
    }

    var tab = this.$route.query.tab;
    if (tab == "charge") {
      this.activeName = "third";
    }
    else if (tab == "project") {
      this.activeName = "fourth";
    }

    //#endregion
  },

  methods: {
    //选择商品
    selectItem(productId) {
      console.log("商品id：" + productId);
      this.payOrder.productId = productId;
      console.log(this.payOrder);
      //this.$router.push({ path: '/order' })
    },

    //选择支付方式
    selectPayType(type) {
      console.log("支付方式：" + type);
      this.payOrder.payType = type;
      //this.$router.push({ path: '/order' })
    },

    //确认支付
    toPay() {
      this.payOrder.payType = 'wxpay';
      if (localStorage.getItem("userId") == "") {
        this.$router.push({ path: "/login" });
      } else {
        //禁用按钮，防止重复提交
        this.payBtnDisabled = true;

        //微信支付
        if (this.payOrder.payType === "wxpay") {
          //调用统一下单接口
          wxPayApi.nativePay(this.payOrder.productId, this.payOrder.userId).then((response) => {
            this.codeUrl = response.data.codeUrl;
            this.orderNo = response.data.orderNo;

            //打开二维码弹窗
            this.codeDialogVisible = true;

            //启动定时器
            this.timer = setInterval(() => {
              //查询订单是否支付成功
              this.queryOrderStatus();
            }, 300);
          });
        }
      }
    },




    //关闭微信支付二维码对话框时让“确认支付”按钮可用
    closeDialog() {
      this.payBtnDisabled = false;
      clearInterval(this.timer);
    },

    // 查询订单状态
    queryOrderStatus() {
      orderInfoApi.queryOrderStatus(this.orderNo).then((response) => {
        console.log("查询订单状态：" + response.code);
        // 支付成功后的页面跳转
        if (response.code === 0) {
          clearInterval(this.timer);
          this.$message({
            message: '支付成功，即将为您跳转到用户首页',
            type: 'success'
          });
          // 三秒后跳转到订单列表
          setTimeout(() => {
            location.reload();
            // this.$router.push({ path: "/User" });
          }, 2000);
        }
      });
    },


    // queryUserInfo() {
    //   userApi.queryUserByID(localStorage.getItem("userId")).then((response) => {
    //     this.balance = response.data.userInfo.balance;
    //     this.vipname = response.data.userInfo.vipname;
    //   })
    // },

  },
};

</script>
<style>
.fee-item {
  margin: 10px 10px;
}
</style>