<template>
  <!-- 公共头 -->
  <header id="header">
    <section class="container">
      <h1 id="logo">
        <a href="#/" title="视维鼎力">
          <img src="../assets/img/logo.png" width="100%" alt="视维鼎力" />
        </a>
      </h1>
      <div class="header-content">
        <ul class="nav">
          <router-link to="/" tag="li" active-class="current" exact>
            <a>首页</a>
          </router-link>
          <!-- <router-link to="/video" tag="li" active-class="current" exact>
            <a>工作台</a>
          </router-link> -->
          <router-link to="/VResource" tag="li" active-class="current" exact>
            <a>素材中心</a>
          </router-link>
          <router-link to="/AIImage" tag="li" active-class="current" exact>
            <a>AI图像处理</a>
          </router-link>
          <router-link to="/AIVideo" tag="li" active-class="current" exact>
            <a>AI视频处理</a>
          </router-link>
          <router-link to="/AIPic" tag="li" active-class="current" exact>
            <a>AI图像创意</a>
          </router-link>
          <router-link to="/AIAudio" tag="li" active-class="current" exact>
            <a>AI音频创意</a>
          </router-link>
          <router-link to="/User" tag="li" active-class="current" exact>
            <a>用户中心</a>
          </router-link>
          <!-- <router-link to="/orders" tag="li" active-class="current">
            <a @click="checkLogin">我的订单</a>
          </router-link> -->
          <!-- <router-link to="/PaymentInfo" tag="li" active-class="current">
            <a @click="checkLogin">交易记录</a>
          </router-link> -->
          <!-- <router-link to="/Soft" tag="li" active-class="current">
            <a>软件中心</a>
          </router-link> -->
          <!-- <router-link to="/Help" tag="li" active-class="current" exact>
            <a>帮助</a>
          </router-link> -->
          <!-- <router-link to="/gpt" tag="li" active-class="current" exact>
            <a>ChatGPT</a>
          </router-link> -->
          <router-link to="/About" tag="li" active-class="current" exact>
            <a>关于我们</a>
          </router-link>
        </ul>
        <ul class="nav" id="loginButton" v-show="displayLogin">
          <router-link to="#" tag="li" active-class="current">
            <el-button :disabled="loginDisable" class="upload-demo-btn" type="primary" id="loginRegButton" style="width: 100%"
              @click="WeChatLogin()">登录/注册</el-button>
            <!-- <a>登录</a> -->
          </router-link>
        </ul>
        <ul class="nav" id="exitButton" v-show="!displayLogin">
          <label id="welcome">欢迎&nbsp;{{ nickname }}&nbsp;|&nbsp;您是 {{ vipname }} </label>
          &nbsp;
          <a @click="exitLogin()">退出</a>
        </ul>
      </div>
      <div class="clear"></div>
    </section>
  </header>
</template>


<script>

import userApi from "../api/user";
import { AuthenticationClient } from "authing-js-sdk";


var userName = localStorage.getItem("userName");
var displayLogin = true;
if (userName != "" && userName != null) {
  displayLogin = false;
} else {
  displayLogin = true;
}

self.setInterval(function () {
  this.nickname = localStorage.getItem("nickName")
}, 1000);



export default {
  data() {
    return {
      userName: localStorage.getItem("userName"), //确认支付按钮是否禁用
      displayLogin: displayLogin,
      loginDisable: false,
      nickname: localStorage.getItem("nickName"),
      vipname: ""
    };
  },

  created() {
    var userid = localStorage.getItem("userId");
    if (userid != "" && userid != null) {
      userApi.queryUserByID(userid).then((response) => {
        if (response.code === 0 && response.data.userInfo != null) {
          this.vipname = response.data.userInfo.vipname;
        }
      })
    }
  },
  methods: {
    exitLogin: function () {
      localStorage.setItem("userName", "");
      localStorage.setItem("userId", "");
      localStorage.setItem("passWord", "");
      userName = ""
      this.$router.push({ path: "/" });
      this.$router.go(0)
      displayLogin = true;
    },

    checkLogin() {
      var userName = localStorage.getItem("userName");
      if (userName != "" && userName != null) {
        //this.$router.push({ path: "/" });
      }
    },



    WeChatLogin: function () {
      this.loginDisable = true;
      setTimeout(() => {
        this.loginDisable = false;
      }, 4000);

      const authenticationClient = new AuthenticationClient({
        appId: "62882ff42bb2e3ac469b18c6",
        appHost: 'https://swdl.authing.cn',
      });
      authenticationClient.social.authorize("swdl", {
        onSuccess: (user) => {
          userApi.queryUserByWxID(user.id).then((response) => {
            if (response.code === 0 && response.data.userInfo != null) {
              setTimeout(() => {

                localStorage.setItem("userName", response.data.userInfo.username);
                localStorage.setItem("passWord", response.data.userInfo.password);
                localStorage.setItem("userId", response.data.userInfo.id)
                localStorage.setItem("nickName", response.data.userInfo.nickname)
                this.$router.push({ path: "/" });
                this.$router.go(0)
              }, 1000);
            }
            else {
              userApi.register(user.id, user.nickname, "123456").then((response) => {
                if (response.code === 0 && response.data.userInfo != null) {
                  localStorage.setItem("userName", response.data.userInfo.username);
                  localStorage.setItem("passWord", response.data.userInfo.password);
                  localStorage.setItem("userId", response.data.userInfo.id);
                  localStorage.setItem("nickName", response.data.userInfo.nickname);
                  setTimeout(() => {
                    this.$router.push({ path: "/" });
                    this.$router.go(0);
                  }, 3000);
                }
              }).catch(e => {
                console.log(e)
              });
            }
          })
        },
        onError: (code, message) => {
          console.log(code)
          console.log(message)
        },
      });
    },
  },
};
</script>
 