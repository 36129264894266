<template>
  <div class="page">
    <div class="banner">
      <el-carousel :interval="5000" class="elCarousel">
        <el-carousel-item class="elCarouselItem">
          <img src="../assets/img/banner1.jpg">
        </el-carousel-item>
        <el-carousel-item class="elCarouselItem">
          <img src="../assets/img/banner2.jpg">
        </el-carousel-item>
      </el-carousel>
    </div>


    <!-- 项目列表 -->
    <div class="section col">
      <h2 class="htitle">
        智能AI处理专家
      </h2>
      <div class="module nowrap">
        <div class="projectdiv  row" v-for="item in projectTypeList" :key="item.value"
          @click="toWorkSpace(item.page, item.tab)">
          <div class="projectard" :class="!item ? 'hide' : ''">
            <div class="cardHead">
              <!-- :src="item.pic" 'cardbg.png'-->
              <img :src="require('../assets/img/' + item.pic)" alt="作品背景" draggable="false"
                onError="getDefaultImg(e, item.worksType)" />
            </div>
            <div class="cardBody">
              <div class="projectName">
                {{ item.protype || '默认标题' }}
              </div>
              <div class="projectDes">
                <span class="describeText">{{ item.protypedesc || '默认描述' }}</span>
                <div class="jumpBtn">
                  <div class="jump-button">
                    立即体验
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section col">
      <h2 class="htitle">
        我们有什么样的AI素材
      </h2>
      <div class="module row">
        <div v-for="item in introduceData" :key="item.titile" class="piece-module">
          <div class="item-title" @click="toRawURL(item.url)">

            {{ item.title }}
          </div>
          <div class="text itemText">{{ item.text }}</div>
        </div>
      </div>
      <el-carousel :interval="5000" height="360px" class="elCarousel introCarousel">
        <el-carousel-item v-for="item in introSlider" :key='item.id' class="elCarouselItem">
          <img :src="item.imgsrc">
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="section col bg">
      <h2 class="htitle">
        视维鼎力AIGC，您的素材处理专家
      </h2>
      <div class="module col">
        <div class="text">
          视维鼎力AIGC在线资源，提供多种视频、图像处理工具，让您轻轻松松在线处理各种素材
          <br>
          在线音频、视频、图像素材库，无版权纠纷，大量人工智能生成的素材随取随用，方便快捷。
        </div>
        <div class="devModule row">
          <div class="devBtn" v-for="item in cardData" :key='item.title'>
            <div class="icon"></div>
            <div @click="toURL(item.url)" style="cursor: hand;">
              <div class="btnTitle">
                {{ item.title }}
              </div>
              <div class="text itemText">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section col">
      <h2 class="htitle">
        您的需求，就是我们的使命！
      </h2>
      <div class="module row center">
        <div class="commentModule comment col">
          <div class="title">用户反馈</div>
        </div>
        <div class="commenWrapper" v-for="item in commentData" :key='item.id'>
          <div class="commentModule col">
            <div class="info row">
              <img :src="item.avatar" alt="" class="icon">
              <div class="userInfo col">
                <div class="username">{{ item.userName }}</div>
                <img src="https://qncdn.aoscdn.com/astro/picwish/_astro/index-comment-star.3c08051f.svg" class="stars"
                  alt="">
                <img :src="item.source" alt="" class="source">
              </div>
            </div>
            <div class="text itemText miniText">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="bottomModule">
        <div class="title">
          开始您的创意之旅吧
        </div>
        <div class="button">
          <a href="#">立即体验</a>
        </div>
      </div>
      <!-- <img src="https://qncdn.aoscdn.com/astro/picwish/_astro/index-free-bg@3840w.ff954de4.png" alt=""> -->
    </div>
  </div>
</template>

<script>
import '../assets/css/index.css';
import { projectTypeList, introduceData, introSlider, cardData, commentData } from '../utils/data'
export default {
  components: {
    // Swiper, SwiperSlide
  },
  data() {
    return {
      projectTypeList, introduceData, introSlider, cardData, commentData
    };
  },
  //页面加载时执行
  created() {
    // projectTypeApi.list().then((response) => {
    //   // let i = response.data.projectTypeList.length % 4;
    //   //  && response.data.projectTypeList.length / 4 >= 2 
    //   // if(i != 0) {
    //   //   i= 4-i;
    //   //   while(i--){
    //   //     this.projectTypeList.push('');
    //   //   }
    //   // }
    //   this.projectTypeList = response.data.projectTypeList.concat(this.projectTypeList);
    // });
  },

  methods: {
    toWorkSpace(page, tab) {
      if (page == "video")
        this.$router.push({ path: "/AIVideo?tab=" + tab });
      else if (page == "pic")
        this.$router.push({ path: "/AIImage?tab=" + tab });
    },
    toRadioVideoScene(searchContent, resourceType, radioVideoScene) {
      this.$router.push({ path: "/VResourceSearch?radioVideoScene=" + radioVideoScene + "&resourceType=" + resourceType + "&searchContent=" + searchContent });
    },
    toURL(url) {
      window.location.href = window.location.href + url;
    },
    toRawURL(url){
      window.location.href = url;
    },
    onClickOpenPage(item) {
      console.log(item);
    },

    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log('slide change');
    }
  },
};
</script>