<template>
    <div>
        <section id="index" class="container">
            <div style="text-align: center;">
                <div style="height:8px"></div>
                <div class="searchInput">
                    <el-input placeholder="请输入搜索内容" v-model="searchContent" class="input-with-select" style="width:50%;"
                        @keyup.enter.native="search()">
                        <el-select v-model="resourceType" slot="prepend" placeholder="全部">
                            <el-option label="全部" value="全部"></el-option>
                            <el-option label="视频" value="视频"></el-option>
                            <el-option label="图片" value="图片"></el-option>
                            <el-option label="音频" value="音频"></el-option>

                        </el-select>
                        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                    </el-input>
                </div>
                <!-- <div style="height:1px"></div> -->
                <div>
                    <el-menu :default-active="resourceType" v-model="resourceType" class="el-menu-demo" mode="horizontal"
                        @select="handleSelect">
                        <el-menu-item index="全部">全部</el-menu-item>
                        <el-menu-item index="视频">视频</el-menu-item>
                        <el-menu-item index="图片">图片</el-menu-item>
                        <el-menu-item index="音频">音频</el-menu-item>

                    </el-menu>
                </div>
                <div style="height:2px"></div>


                <div style="text-align: left;">
                    <div class="radioRow">
                        <el-button type="text" size="medium">场景：&nbsp;</el-button>
                        <el-radio-group v-model="selectedScene" @change="resetPageIndex">
                            <el-radio-button v-for="scene in selectedSceneList" :key="scene.name" :label="scene.name">
                                {{ scene.name }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="radioRow">
                        <el-button type="text" size="medium">时长：&nbsp;</el-button>
                        <el-radio-group v-model="selectedStyle" @change="resetPageIndex">
                            <el-radio-button
                                v-for="style in selectedStyleList && selectedStyleList.filter(s => s.scenename === selectedScene)"
                                :key="style.name" :label="style.name">
                                {{ style.name }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
            </div>

            <div id="projectdiv" style="text-align:center;">
                <ul>
                    <li v-for="v in  list" :key="v.globalid">
                        <div v-if="v.type === '视频'">
                            <el-image :src=v.images @click="toItem(v.globalid)"></el-image>
                            <center>
                                <div style="width:fit-content;z-index: 999;text-align: center;font-weight: bolder;">
                                    {{ v.title }}
                                </div>
                            </center>
                        </div>
                        <div v-if="v.type === '音频'">
                            <div>
                                <audio :src=v.thumbnail controls>
                                    <source :src=v.thumbnail type="audio/mpeg">
                                </audio>
                                <center>
                                    <table style="text-align: center;">
                                        <tr>
                                            <td>
                                                <div
                                                    style="width:fit-content;z-index: 999; text-align: center;font-weight: bolder;">
                                                    {{ v.title }}
                                                </div>
                                            </td>
                                            <td>&nbsp;&nbsp;&nbsp;</td>
                                            <td><el-button @click="toAudioItem(v.globalid)" type="primary">下载</el-button>
                                            </td>
                                        </tr>
                                    </table>
                                </center>
                            </div>
                        </div>

                        <div v-if="v.type === '图片'">
                            <el-image :src=v.thumbnail fit="cover" @click="toPicItem(v.globalid)"></el-image>
                            <center>
                                <div style="width:fit-content;z-index: 999;text-align: center;font-weight: bolder;"
                                    @click="toPicItem(v.globalid)">
                                    {{ v.title }}
                                </div>
                            </center>
                        </div>
                    </li>
                </ul>
            </div>



            <div style="text-align:center">
                <span>&nbsp;</span>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                    :total="totalCount">
                </el-pagination>
            </div>

        </section>
    </div>
</template>
<script>
import esprojectApi from "../api/es_product";
import ObsClient from 'esdk-obs-browserjs/src/obs'
export default {
    data() {
        return {
            searchContent: '',
            resourceType: "全部",

            radioVideoScene: "全部",
            radioVideoStyle: "全部",

            radioAudioScene: "全部",
            radioAudioStyle: "全部",

            radioPicScene: "全部",
            radioPicStyle: '全部',

            // imgPathOBSList: [],

            // countNum: 0,

            VideoSceneList: [

                { id: 0, name: '全部' },
                { id: 4, name: '实拍视频' },
                { id: 1, name: '珍贵史料' },
                { id: 2, name: '经典电影' },
                { id: 3, name: '经典动漫' },

            ],
            VideoStyleList: [
                { id: -1, name: '全部', scenename: '全部' },

                { id: 0, name: '全部', scenename: '珍贵史料' },
                { id: 0, name: '30s以下', scenename: '珍贵史料' },
                { id: 1, name: '30秒~1分钟', scenename: '珍贵史料' },
                { id: 2, name: '1分钟~2分钟', scenename: '珍贵史料' },
                { id: 3, name: '2分钟以上', scenename: '珍贵史料' },

                { id: 0, name: '全部', scenename: '经典电影' },
                { id: 0, name: '30s以下', scenename: '经典电影' },
                { id: 1, name: '30秒~1分钟', scenename: '经典电影' },
                { id: 2, name: '1分钟~2分钟', scenename: '经典电影' },
                { id: 3, name: '2分钟以上', scenename: '经典电影' },

                { id: 0, name: '全部', scenename: '经典动漫' },
                { id: 0, name: '30s以下', scenename: '经典动漫' },
                { id: 1, name: '30秒~1分钟', scenename: '经典动漫' },
                { id: 2, name: '1分钟~2分钟', scenename: '经典动漫' },
                { id: 3, name: '2分钟以上', scenename: '经典动漫' },

                { id: 0, name: '全部', scenename: '实拍视频' },
                { id: 0, name: '交通工具', scenename: '实拍视频' },
                { id: 1, name: '动物世界', scenename: '实拍视频' },
                { id: 2, name: '城市建筑', scenename: '实拍视频' },
                { id: 3, name: '室内家居', scenename: '实拍视频' },
                { id: 3, name: '纹理', scenename: '实拍视频' },
                { id: 3, name: '背景', scenename: '实拍视频' },
                { id: 3, name: '自然风景', scenename: '实拍视频' },
                { id: 3, name: '餐饮美食', scenename: '实拍视频' },
            ],



            AudioSceneList: [
                { id: 0, name: '全部' },
                { id: 1, name: '动物声' },
                { id: 2, name: '环境声' },
                { id: 3, name: '人声' },
                { id: 4, name: '家庭' },
                { id: 5, name: '车辆' },
                { id: 6, name: '影视配乐' },
            ],
            AudioStyleList: [
                { id: -1, name: '全部', scenename: '全部' },

                { id: 0, name: '全部', scenename: '动物声' },
                { id: 1, name: '蝙蝠', scenename: '动物声' },
                { id: 2, name: '熊', scenename: '动物声' },
                { id: 3, name: '猫', scenename: '动物声' },

                { id: 4, name: '全部', scenename: '环境声' },
                { id: 5, name: '大森林', scenename: '环境声' },
                { id: 6, name: '下雨', scenename: '环境声' },
                { id: 7, name: '大海', scenename: '环境声' },

                { id: 8, name: '全部', scenename: '人声' },
                { id: 9, name: '呼吸声', scenename: '人声' },
                { id: 10, name: '咳嗽声', scenename: '人声' },
                { id: 11, name: '打嗝声', scenename: '人声' },

                { id: 12, name: '全部', scenename: '家庭' },
                { id: 13, name: '烘干机', scenename: '家庭' },
                { id: 14, name: '冰箱压缩机', scenename: '家庭' },
                { id: 15, name: '燃气灶点火', scenename: '家庭' },
                { id: 16, name: '抽油烟机', scenename: '家庭' },
                { id: 17, name: '微波炉', scenename: '家庭' },
                { id: 18, name: '表', scenename: '家庭' },

                { id: 19, name: '全部', scenename: '车辆' },
                { id: 20, name: '救护车', scenename: '车辆' },
                { id: 21, name: '小汽车', scenename: '车辆' },
                { id: 22, name: '飞机', scenename: '车辆' },
                { id: 23, name: '警车', scenename: '车辆' },
                { id: 24, name: '轮船', scenename: '车辆' },
                { id: 25, name: '火车', scenename: '车辆' },

                { id: 26, name: '全部', scenename: '影视配乐' },
                { id: 27, name: '轻松愉快', scenename: '影视配乐' },
                { id: 28, name: '愤怒震撼', scenename: '影视配乐' },
                { id: 29, name: '激情动感', scenename: '影视配乐' },
                { id: 30, name: '诙谐幽默', scenename: '影视配乐' },
            ],

            PicSceneList: [
                { id: 0, name: '全部' },
                { id: 1, name: '餐饮美食' },
                { id: 2, name: '城市建筑' },
                { id: 3, name: '动物世界' },
                { id: 5, name: '商务办公' },
                { id: 6, name: '生活状态' },
                { id: 7, name: '室内家居' },
                { id: 8, name: '自然风景' },
            ],
            PicStyleList: [
                { id: -1, name: '全部', scenename: '全部' },

                { id: 0, name: '全部', scenename: '餐饮美食' },
                { id: 1, name: '蛋糕甜品', scenename: '餐饮美食' },
                { id: 2, name: '海鲜水产', scenename: '餐饮美食' },
                { id: 3, name: '酒水饮料', scenename: '餐饮美食' },
                { id: 4, name: '水果蔬菜', scenename: '餐饮美食' },
                { id: 5, name: '西餐美食', scenename: '餐饮美食' },
                { id: 6, name: '中餐美食', scenename: '餐饮美食' },

                { id: 7, name: '全部', scenename: '城市建筑' },
                { id: 8, name: '古代建筑', scenename: '城市建筑' },
                { id: 9, name: '建筑空间', scenename: '城市建筑' },
                { id: 10, name: '现代建筑', scenename: '城市建筑' },

                { id: 11, name: '全部', scenename: '动物世界' },
                { id: 12, name: '海里', scenename: '动物世界' },
                { id: 13, name: '陆地', scenename: '动物世界' },
                { id: 14, name: '天空', scenename: '动物世界' },

                { id: 15, name: '全部', scenename: '商务办公' },
                { id: 16, name: '办公场所', scenename: '商务办公' },
                { id: 17, name: '商务手势', scenename: '商务办公' },
                { id: 18, name: '团队协作', scenename: '商务办公' },

                { id: 19, name: '全部', scenename: '生活状态' },
                { id: 20, name: '读书写字', scenename: '生活状态' },
                { id: 21, name: '锻炼身体', scenename: '生活状态' },
                { id: 22, name: '做饭吃饭', scenename: '生活状态' },

                { id: 23, name: '全部', scenename: '室内家居' },
                { id: 24, name: '家用电器', scenename: '室内家居' },
                { id: 25, name: '欧式设计', scenename: '室内家居' },
                { id: 26, name: '现代风格', scenename: '室内家居' },
                { id: 27, name: '中式风格', scenename: '室内家居' },

                { id: 28, name: '全部', scenename: '自然风景' },
                { id: 29, name: '海浪沙滩', scenename: '自然风景' },
                { id: 30, name: '花草树木', scenename: '自然风景' },
                { id: 31, name: '蓝天白云', scenename: '自然风景' },
                { id: 32, name: '山川河流', scenename: '自然风景' },
                { id: 33, name: '宇宙星空', scenename: '自然风景' },
            ],

            list: [],
            currentPage: 1,
            totalCount: 1,
            PageSize: 20,
        }
    },
    created() {
        this.searchContent = this.$route.query.searchContent
        this.resourceType = this.$route.query.resourceType
        this.radioVideoScene = this.$route.query.radioVideoScene
        this.radioPicScene = this.$route.query.radioPicScene
        this.radioAudioScene = this.$route.query.radioAudioScene

        if (this.radioVideoScene == null) {
            this.radioVideoScene = "全部"
        }
        if (this.radioAudioScene == null) {
            this.radioAudioScene = "全部"
        }
        if (this.radioPicScene == null) {
            this.radioPicScene = "全部"
        }
        // this.search()
    },

    computed: {
        listenChange() {
            const { resourceType, radioVideoScene, radioVideoStyle, radioAudioScene, radioAudioStyle, radioPicScene, radioPicStyle } = this
            return { resourceType, radioVideoScene, radioVideoStyle, radioAudioScene, radioAudioStyle, radioPicScene, radioPicStyle }
        },
        selectedScene: {
            get() {
                if (this.resourceType === '视频') {
                    return this.radioVideoScene;
                } else if (this.resourceType === '音频') {
                    return this.radioAudioScene;
                } else if (this.resourceType === '图片') {
                    return this.radioPicScene;
                } else {
                    return null;
                }
            },
            set(value) {
                if (this.resourceType === '视频') {
                    this.radioVideoScene = value;
                } else if (this.resourceType === '音频') {
                    this.radioAudioScene = value;
                } else if (this.resourceType === '图片') {
                    this.radioPicScene = value;
                }
            }
        },
        selectedStyle: {
            get() {
                if (this.resourceType === '视频') {
                    return this.radioVideoStyle;
                } else if (this.resourceType === '音频') {
                    return this.radioAudioStyle;
                } else if (this.resourceType === '图片') {
                    return this.radioPicStyle;
                } else {
                    return null;
                }
            },
            set(value) {
                if (this.resourceType === '视频') {
                    this.radioVideoStyle = value;
                } else if (this.resourceType === '音频') {
                    this.radioAudioStyle = value;
                } else if (this.resourceType === '图片') {
                    this.radioPicStyle = value;
                }
            }
        },

        selectedSceneList() {
            if (this.resourceType === '视频') {
                return this.VideoSceneList;
            } else if (this.resourceType === '音频') {
                return this.AudioSceneList;
            } else if (this.resourceType === '图片') {
                return this.PicSceneList;
            } else {
                return null;
            }
        },
        selectedStyleList() {
            if (this.resourceType === '视频') {
                return this.VideoStyleList;
            } else if (this.resourceType === '音频') {
                return this.AudioStyleList;
            } else if (this.resourceType === '图片') {
                return this.PicStyleList;
            } else {
                return null;
            }
        }
    },

    watch: {
        listenChange() {
            this.search()
        },
        radioAudioScene() {
            this.radioAudioStyle = '全部';
        },
        radioVideoScene() {
            this.radioVideoStyle = '全部';
        },
        radioPicScene() {
            this.radioPicStyle = "全部";
        }

    },
    methods: {
        handleSelect(resourceType) {
            this.resourceType = resourceType
            this.resetPageIndex()
        },
        search() {
            esprojectApi.getSearchCount(this.resourceType, this.selectedScene, this.selectedStyle, this.searchContent, (this.currentPage - 1) * this.PageSize, this.PageSize).then((response) => {
                this.totalCount = response
            });
            esprojectApi.list(this.resourceType, this.selectedScene, this.selectedStyle, this.searchContent, (this.currentPage - 1) * this.PageSize, this.PageSize).then((response) => {
                this.list = eval(response);
            });
        },
        toItem(id) {
            this.$router.push({ path: "/VResourceItem?id=" + id });
        },
        toAudioItem(id) {
            this.$router.push({ path: "/VResourceAudioItem?id=" + id });
        },
        toPicItem(id) {
            this.$router.push({ path: "/VResourcePicItem?id=" + id });
        },
        handleSizeChange(val) {
            this.PageSize = val
            this.currentPage = 1
            this.search()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.search()
        },
        resetPageIndex() {
            this.currentPage = 1;
            this.search()
        },
        getAudioByID(tmp_globalid, format, firstLevel, secondLevel) {
            var bucketName = "swdlresource";
            var obs = this.getObsClient();
            obs.getObject({
                Bucket: bucketName,
                Key: "音频" + "/" + firstLevel + "/" + secondLevel + "/Audio/" + tmp_globalid + "." + format.toLowerCase(),
                SaveByType: 'file'
            }).then(function (result) {
                console.log(result.InterfaceResult.Content.SignedUrl)
                return result.InterfaceResult.Content.SignedUrl;
            });
        },

        getObsClient() {
            var ak = "A8LVRMADNPSSAXOD0CQQ";
            var sk = "mjMVhyVEmdJoP4nLQ0QEcwFl7Le02FrwBwrkanZP";
            var server = "obs.cn-north-4.myhuaweicloud.com";
            return new ObsClient({
                access_key_id: ak,
                secret_access_key: sk,
                server: server,
                timeout: 60 * 5,
            });
        },
    }
}
</script>

<style>
.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}


#projectdiv img {
    width: 300px;
    height: 288px;
}

#projectdiv ul li {
    display: inline-block;
}
.el-menu-demo {
  background-color: transparent;
}

.radioRow {
    margin-top: 10px;
}
.searchInput {
    margin: 10px 0;
}
</style>