<template>
  <div class="bg-fa of" style="padding: 40px;">
    <el-alert
        title="项目提交成功，请到项目中心查看处理进度！"
        type="success">
    </el-alert>
  </div>
</template>

<script>
export default {

  created() {
    setTimeout(() => {
              this.$router.push({ path: "/Project" });
            }, 3000);
  },
}
</script>