<template>
    <div>
        <el-tabs v-model="activeName" type="card" tab-position="left" style="width: 100%;">
            <el-tab-pane label="视频去字幕水印台标" name="wrapper">
                <VideoWrapper></VideoWrapper>
                
            </el-tab-pane>
            <el-tab-pane label="视频清晰度提升" name="chaofen">
                <VideoChaofen></VideoChaofen>
                
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import VideoWrapper from './VideoWrapper.vue'
import VideoChaofen from './VideoChaofen.vue'

export default {
    components: {
        VideoWrapper,
        VideoChaofen
    },
    data() {
        return {
            activeName: 'wrapper'
        }
    },
    created() {
        var tab = this.$route.query.tab;
        if (tab != "" && tab != undefined)
            this.activeName = tab
    }
}
</script>