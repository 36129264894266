import request from '@/utils/request'

export default{

  //查询发票列表
  list(userId) {
    return request({
      url: '/api/receipt/listByUser/'+userId,
      method: 'post'
    })
  },

  getReceipt(receiptID){
    return request({
      url: '/api/receipt/getReciptByID/'+receiptID,
      method: 'post'
    })
  },

  addReceipt(price,type,head,number,postname,postphone,postaddress,userid){
    return request({
      url: '/api/receipt/addReceipt/'+price+'/'+type+'/'+head+'/'+number+'/'+postname+'/'+postphone+'/'+postaddress+'/'+userid,
      method: 'post'
    })
  },

  getRestRecp(userId){
    return request({
      url: '/api/receipt/getRestRecp/'+userId,
      method: 'post'
    })
  },
  
}