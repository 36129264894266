<template>
    <div>
        <section id="index" class="container">
            <table>
                <tr>
                    <td style="width:60%">
                        <img src="../assets/img/downsoft.png" style="width: 100%;" />
                    </td>
                    <td style="vertical-align: middle;">
                        <ul style="text-align: center;">
                            <li><img src="../assets/img/d1.png" style="width: 60%;" /></li>
                            <br/>
                            <!-- <li style="text-align: center;"><el-button type="primary" round @click="downSoft">立即下载</el-button></li> -->
                            <br/>
                            <li>
                                <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;更新时间：2023-02-12
                                    &nbsp;&nbsp;&nbsp;版本号:V1.0.0</h4>
                                <p>&nbsp;</p>
                                <p></p>
                                <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;软件大小：429.11M</h4>
                            </li>
                            <br/>
                            <br/>
                            <li style="text-align: center;"><el-button type="primary" class="upload-demo-btn" round @click="downSoft">立即下载</el-button></li>
                        </ul>
                    </td>
                </tr>
            </table>
        </section>
    </div>
</template>
<script>
export default {
    methods: {
        downSoft() {
            window.open("/static/subtitle_erase.exe")
        }
    }
}
</script>


<style></style>
