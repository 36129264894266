<template>
    <div>
        <section id="index" class="container">

            <div>
                <div style="margin-top: 15px; text-align: center;">
                    <el-input placeholder="请输入搜索内容" v-model="searchContent" class="input-with-select" style="width:50%;"
                        @keyup.enter.native="toSearchList(searchContent, resourceType)">
                        <el-select v-model="resourceType" slot="prepend" placeholder="全部">
                            <el-option label="视频" value="视频"></el-option>
                            <el-option label="图片" value="图片"></el-option>
                            <el-option label="音频" value="音频"></el-option>
                        </el-select>
                        <el-button slot="append" icon="el-icon-search"
                            @click="toSearchList(searchContent, resourceType)"></el-button>
                    </el-input>
                </div>
                <div style="height:10px"></div>

                <!-- 
                <i class=""></i>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2> -->



                <el-row class="titleRow">
                    <el-col :span="16">
                        <div>
                            <el-link @click="toRadioVideoScene('', '视频', '全部')" target="_blank"
                                icon="el-icon-video-camera-solid" style="font-size: 30px; ">精选视频</el-link>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="selectRow">
                            <el-button @click="toRadioVideoScene('', '视频', '实拍视频')">实拍视频</el-button>
                            <el-button @click="toRadioVideoScene('', '视频', '珍贵史料')">珍贵史料</el-button>
                            <el-button @click="toRadioVideoScene('', '视频', '经典电影')">经典电影</el-button>
                            <el-button @click="toRadioVideoScene('', '视频', '经典动漫')">经典动漫</el-button>
                        </div>
                    </el-col>
                    <el-col :span="2" style="vertical-align: center;">
                        <el-link icon="el-icon-d-arrow-right" style="font-size: large;margin-top: 5px;"
                            @click="toRadioVideoScene('', '视频', '全部')">查看更多</el-link>
                    </el-col>
                </el-row>


                <div id="projectdiv" style="text-align:center;">
                    <ul>
                        <li v-for="v in listVideo" :key="v.TITLE">
                            <el-image :src=v.IMAGES @click="toItem(v.GLOBALID)"></el-image>&nbsp;&nbsp;
                            <center>
                                <div style="width:fit-content;z-index: 999;">
                                    {{ v.TITLE }}
                                </div>
                            </center>
                        </li>
                    </ul>
                </div>



                <!-- ------------------------------ -->

                <el-divider></el-divider>
                <el-row class="titleRow">
                    <el-col :span="16">
                        <el-link target="_blank" icon="el-icon-picture" style="font-size: 30px;"
                            @click="toRadioPicScene('', '图片', '全部')">精选图片</el-link></el-col>
                    <el-col :span="6" class="selectRow">
                        <el-button @click="toRadioPicScene('', '图片', '动物世界')">动物世界</el-button>
                        <el-button @click="toRadioPicScene('', '图片', '城市建筑')">城市建筑</el-button>
                        <el-button @click="toRadioPicScene('', '图片', '餐饮美食')">餐饮美食</el-button>
                    </el-col>
                    <el-col :span="2">
                        <div style="vertical-align: center;">
                            <el-link icon="el-icon-d-arrow-right" style="font-size: large;margin-top: 10px;"
                                @click="toRadioPicScene('', '图片', '全部')">查看更多</el-link>
                        </div>
                    </el-col>
                </el-row>
                <div id="projectdiv" style="text-align:center;">
                    <ul>
                        <li v-for="v in listImg" :key="v.value">

                            <el-image :src=v.THUMBNAIL @click="toPicItem(v.GLOBALID)"></el-image>
                            <center>
                                <div style="width:fit-content; z-index: 999;">
                                    {{ v.TITLE }}
                                </div>
                            </center>
                        </li>
                    </ul>
                </div>


                <el-divider></el-divider>
                <el-row class="titleRow">
                    <el-col :span="16">
                        <el-link target="_blank" icon="el-icon-headset" style="font-size: 30px;"
                            @click="toRadioAudioScene('', '音频', '全部')">精选音频</el-link></el-col>
                    <el-col :span="6" class="selectRow">
                        <el-button @click="toRadioAudioScene('', '音频', '动物声')">动物声</el-button>
                        <el-button @click="toRadioAudioScene('', '音频', '环境声')">环境声</el-button>
                        <el-button @click="toRadioAudioScene('', '音频', '人声')">人声</el-button>
                    </el-col>
                    <el-col :span="2">
                        <div style="vertical-align: center;">
                            <el-link icon="el-icon-d-arrow-right" style="font-size: large;margin-top: 10px;"
                                @click="toRadioAudioScene('', '音频', '全部')">查看更多</el-link>
                        </div>
                    </el-col>
                </el-row>
                <div id="projectdiv" style="text-align:center;">
                    <ul>
                        <li v-for="v in listAudio" :key="v.value">
                            <h1>&nbsp;</h1>
                            <!-- <img src="../assets/img/audio.png" /> -->
                            <img class="el-image" src="../assets/img/audio.png" @click="toAudioItem(v.GLOBALID)" />
                            <br />
                            <audio controls class="audioControl">
                                <source :src=v.THUMBNAIL type="audio/mpeg">
                            </audio>
                            <center>
                                <div style="width:fit-content; z-index: 999;">
                                    {{ v.TITLE }}
                                </div>
                            </center>
                            <p>&nbsp;</p>
                        </li>
                    </ul>
                </div>


            </div>
        </section>


    </div>
</template>
<script>

import esprojectApi from "../api/es_product";
// import VueAudio from 'vue-audio';
export default {
    data() {
        return {
            searchContent: '',
            videoType: "",
            listVideo: [],
            listAudio: [],
            listImg: [],
            resourceType: "全部",
            radioAllFormat: "全部",
            radioVideoScene: "全部",
            radioVideoTime: "全部",
            radioAudioScene: "全部",
            radioAudioStyle: "全部",
        }
    },
    // components: {
    //     VuetifyAudio: () => import('vuetify-audio'),
    // },
    // components:{
    //     'vue-audio':VueAudio
    // },
    created() {
        var that = this;
        // esprojectApi.list().then((response) => {
        //     this.list = eval(response);
        // });



        // esprojectApi.list("视频", this.radioAllFormat, this.radioVideoScene, this.radioVideoTime, this.radioAudioScene, this.radioAudioStyle,this.searchContent).then((response) => {
        //         this.listVideo = eval(response);
        //     });
        // esprojectApi.list("音频", this.radioAllFormat, this.radioVideoScene, this.radioVideoTime, this.radioAudioScene, this.radioAudioStyle,this.searchContent).then((response) => {
        //         this.listAudio = eval(response);
        //     });

        esprojectApi.getStaredVideo().then((response) => {
            // var s = JSON.stringify(response)
            that.listVideo = response
        }),
            esprojectApi.getStaredAudio().then((response) => {
                // var s = JSON.stringify(response)
                that.listAudio = response
            })
        esprojectApi.getStaredImg().then((response) => {
            // var s = JSON.stringify(response)
            that.listImg = response
        })

    },
    methods: {
        toSearchList(searchContent, resourceType) {
            this.$router.push({ path: "/VResourceSearch?resourceType=" + resourceType + "&searchContent=" + searchContent });
        },

        toRadioVideoScene(searchContent, resourceType, radioVideoScene) {
            this.$router.push({ path: "/VResourceSearch?radioVideoScene=" + radioVideoScene + "&resourceType=" + resourceType + "&searchContent=" + searchContent });
        },
        toRadioPicScene(searchContent, resourceType, radioPicScene) {
            this.$router.push({ path: "/VResourceSearch?radioPicScene=" + radioPicScene + "&resourceType=" + resourceType + "&searchContent=" + searchContent });
        },
        toRadioAudioScene(searchContent, resourceType, radioAudioScene) {
            this.$router.push({ path: "/VResourceSearch?radioAudioScene=" + radioAudioScene + "&resourceType=" + resourceType + "&searchContent=" + searchContent });
        },


        toItem(id) {
            this.$router.push({ path: "/VResourceItem?id=" + id });
        },
        toPicItem(id) {
            this.$router.push({ path: "/VResourcePicItem?id=" + id });
        },
        toAudioItem(id) {
            this.$router.push({ path: "/VResourceAudioItem?id=" + id });
        }
    },
}
</script>

<style>
.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}


#projectdiv img {
    width: 350px;
    height: 288px;
}

#projectdiv ul li {
    display: inline-block;
}
.titleRow {
    margin: 20px 0;
}
.selectRow {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px 0 0;
    line-height: 20px;
}
.audioControl {
    background-color: transparent;
    height: 60px;
    margin-top: 5px;
}
</style>