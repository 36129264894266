<template>
    <div>
        <section id="index" class="container">
            <br /><br />
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>素材中心</el-breadcrumb-item>
                <el-breadcrumb-item>音频素材</el-breadcrumb-item>
                <el-breadcrumb-item>{{ this.category }}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-row :gutter="20">
                <el-col :span="18">
                    <br />
                    <div class="grid-content bg-purple" style="text-align: center;">
                        <h2><i class="el-icon-video-camera-solid"></i>{{ this.title }}</h2>
                    </div>
                    <el-divider></el-divider>
                    <div ref="content">
                        <audio :src="audioSrc" controls>
                            <source :src="audioSrc" type="audio/mpeg">
                        </audio>
                    </div>

                    <el-divider></el-divider>
                    {{ this.description }}
                    <el-divider></el-divider>

                    <div class="grid-content bg-purple">

                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content bg-purple-light">
                        <br /><br /><br /><br />
                        <el-card shadow="hover">
                            <el-button type="primary" class="upload-demo-btn" icon="el-icon-download"
                                style="width:100%;height: 50px; font-size: large;" v-on:click="downFile"
                                :disabled="downDisabled">立即下载</el-button>
                            <h4 style="text-align: center;">
                                <br />
                                <i class="el-icon-star-on"></i>&nbsp;672&nbsp;
                                <i class="el-icon-view"></i>&nbsp;999&nbsp;
                                <i class="el-icon-edit"></i>&nbsp;175&nbsp;
                            </h4>
                            <br />
                            <div style="text-align: center;">您今日剩余音频下载次数 {{ audiorestcount }}</div>
                        </el-card>
                        <br />
                        <el-card shadow="hover" style="font-size: medium;">

                            <el-row>
                                <el-col :span="16">作者</el-col>
                                <el-col :span="8">{{ this.author }}</el-col>
                            </el-row>
                            <br />
                            <el-row>
                                <el-col :span="16">时长</el-col>
                                <el-col :span="8">{{ this.duration }}&nbsp;秒</el-col>
                            </el-row>
                            <br />
                            <el-row>
                                <el-col :span="16">格式</el-col>
                                <el-col :span="8">{{ this.vformat }}</el-col>
                            </el-row>
                        </el-card>


                        <el-card shadow="hover">
                            <el-row>
                                <el-col :span="24" style="text-align: center;font-size: large;">
                                    <span style="font-size: large;">音频说明</span>
                                </el-col>
                            </el-row>
                            <br />
                            <el-row>
                                <el-col :span="24"
                                    style="text-align: left;font-size: small;font-family:'Courier New', Courier, monospace">
                                    {{ description }}
                                </el-col>
                            </el-row>
                        </el-card>

                    </div>
                </el-col>
            </el-row>
        </section>
    </div>
</template>

<script>
import esprojectApi from "../api/es_product";
import ObsClient from 'esdk-obs-browserjs/src/obs'
import userApi from '../api/user';

export default {
    data() {
        return {
            downDisabled: false,
            id: '',
            globalid: '',
            objectInfo: [],
            title: '',
            sub_title: '',
            description: '',
            type: '',
            category: '',
            style: '',
            duration: '',
            thumbnail: '',
            images: '',
            audioSrc: '',
            pixle: '',
            author: '',
            price: '',
            vformat: '',
            obssourceurl: '',
            obszipurl: '',
            userid: '',
            videorestcount: 0,
            audiorestcount: 0,
            imgrestcount: 0,
        }
    },
    created() {
        this.id = this.$route.query.id
        if (this.id != "" || this.id != null) {
            esprojectApi.getByID(this.id).then((response) => {
                this.objectInfo = eval(response)[0]._source;
                this.globalid = this.objectInfo.globalid;
                this.title = this.objectInfo.title
                this.sub_title = this.objectInfo.sub_title
                this.type = this.objectInfo.type
                this.category = this.objectInfo.category
                this.style = this.objectInfo.style
                this.duration = new Number(this.objectInfo.duration).toFixed(2)
                this.thumbnail = this.objectInfo.thumbnail
                this.images = this.objectInfo.images
                this.pixle = this.objectInfo.pixle
                this.author = this.objectInfo.author
                this.price = this.objectInfo.price
                this.vformat = this.objectInfo.format.toLowerCase()
                this.description = this.objectInfo.description
                var that = this;

                var bucketName = "swdlresource";
                var obs = this.getObsClient();
                obs.getObject({
                    Bucket: bucketName,
                    Key: "音频" + "/Audio/" + this.globalid + "." + this.vformat,
                    SaveByType: 'file'
                }).then(function (result) {
                    that.audioSrc = result.InterfaceResult.Content.SignedUrl;
                });

                obs.getObject({
                    Bucket: bucketName,
                    Key: "音频" + "/Zip/" + this.globalid + ".zip",
                    SaveByType: 'file'
                }).then(function (result) {
                    that.obszipurl = result.InterfaceResult.Content.SignedUrl;
                });
            });
        }

        this.queryRestTimes()

    },
    methods: {
        toSearchList(searchContent) {
            this.$router.push({ path: "/VResourceSearch", query: { searchContent } });
        },

        downFile() {
            if (this.userid == "" || this.userid == null) {
                this.$showWarning("您还没有登录，请点击右上角登录后再使用")
                return
            }
            else {
                if (this.audiorestcount > 0) {
                    window.open(this.obszipurl, '_self')
                    this.downDisabled = true
                    this.audiorestcount = this.audiorestcount - 1
                    userApi.updateRestTime(this.userid, this.videorestcount, this.audiorestcount, this.imgrestcount)
                }
                else {
                    this.$showWarning("您当日下载次数已用完")
                }
            }
        },
        getObsClient() {
            var ak = "A8LVRMADNPSSAXOD0CQQ";
            var sk = "mjMVhyVEmdJoP4nLQ0QEcwFl7Le02FrwBwrkanZP";
            var server = "obs.cn-north-4.myhuaweicloud.com";
            return new ObsClient({
                access_key_id: ak,
                secret_access_key: sk,
                server: server,
                timeout: 60 * 5,
            });
        },
        queryRestTimes() {
            this.userid = localStorage.getItem("userId");
            if (this.userid == "" || this.userid == null) {
                this.$showWarning("您还没有登录，请点击右上角登录后再使用")
                return
            }
            else {
                userApi.queryUserByID(this.userid).then((response) => {
                    this.videorestcount = response.data.userInfo.videorestcount;
                    this.audiorestcount = response.data.userInfo.audiorestcount;
                    this.imgrestcount = response.data.userInfo.imgrestcount;
                });
            }
        }
    }
}
</script>