<template>
  <div class="login-wrap">
    <!-- <el-form class="login-container"> -->
      <!-- <h1 class="title">用户登录</h1> -->
      <!-- <el-form-item label="账号">
        <el-input type="text" v-model="username" placeholder="登录账号" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="登录密码">
        <el-input type="password" v-model="password" placeholder="登录密码" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" style="width: 100%" @click="dosubmit()">登录</el-button>
      </el-form-item> -->

      <!-- <el-form-item label="没有账号？" class="regLink"> -->
        <!-- <router-link :to="{ path: '/register' }"> 立即注册 </router-link> -->
        <!-- <el-input type="password" v-model="password" placeholder="登录密码" autocomplete="off"></el-input> -->
      <!-- </el-form-item> -->
      <!-- <el-form-item> -->

        <!-- <el-button >微信登录</el-button> -->
      <!-- </el-form-item> -->
    <!-- </el-form> -->


      <div>
        <img src="../assets/img/wechat.png" @click="WeChatLogin()" style="width:10%" />
      </div>
      <div>
        <h3 @click="WeChatLogin()">点击使用微信扫码登录</h3>
      </div>

  </div>
</template>
 
<script>
import userApi from "../api/user";
import { AuthenticationClient } from "authing-js-sdk";

export default {
  name: "login",
  data: function () {
    return {
      username: "admin",
      password: "111111",
    };
  },
  methods: {
    dosubmit: function () {
      // let params = {
      //   username: this.username,
      //   password: this.password
      // };

      userApi.login(this.username, this.password).then((response) => {
        if (response.code === 0) {
          setTimeout(() => {
            localStorage.setItem("userName", this.username);
            localStorage.setItem("passWord", this.password);
            localStorage.setItem("userId", response.data.userInfo.id)
            this.$router.push({ path: "/" });
            this.$router.go(0)
          }, 1000);
        }
      });
    },
    WeChatLogin: function () {
      const authenticationClient = new AuthenticationClient({
        appId: "62882ff42bb2e3ac469b18c6",
        appHost: 'https://swdl.authing.cn',
      });

      authenticationClient.social.authorize("swdl", {
        onSuccess: (user) => {
          userApi.queryUserByWxID(user.id).then((response) => {
            if (response.code == 0 && response.data.userInfo != null) {
              setTimeout(() => {
                localStorage.setItem("userName", response.data.userInfo.username);
                localStorage.setItem("passWord", response.data.userInfo.password);
                localStorage.setItem("userId", response.data.userInfo.id)
                this.$router.push({ path: "/" });
                this.$router.go(0)
              }, 1000);

            }
            else {
              console.log("微信ID不存在")
              localStorage.setItem("wxID", user.id);
              userApi.register(user.id, user.nickname, "").then((response) => {
                  console.log("注册用户信息：" + response.code);
                  // 支付成功后的页面跳转
                  if (response.code === 0) {
                    // 三秒后跳转到订单列表
                    setTimeout(() => {
                      localStorage.setItem("username", user.id);
                      this.$router.push({ path: "/Login" });
                      this.$router.go(0);
                    }, 1000);
                  }
                });
            }
          }).catch(e => {
            console.log(e)
          })
          console.log(user);
        },
        onError: (code, message) => {
          console.log(code)
          console.log(message)
        },
      });
    },
  },

};
</script>
 
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  /* background-color: #112346; */
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 100%;
  text-align: center;
}

.login-container {
  border-radius: 10px;
  margin: 0px auto;
  width: 350px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.regLink {
  border-radius: 10px;
  padding: 10px 30px 10px 30px;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: right;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}
</style>