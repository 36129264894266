// axios 发送ajax请求
import requestES from '@/utils/requestES'

export default {

  //查询商品列表
  listAll(resourceType, selectedScene, selectedStyle, searchContent) {
    return requestES({
      // url: '/search?resourceType=' + resourceType + '&selectedScene=' + selectedScene + '&selectedStyle=' + selectedStyle + '&searchContent=' + searchContent + '',
      url: '/search?resourceType=' + resourceType + '&selectedScene=' + selectedScene + '&selectedStyle=' + selectedStyle + '&searchContent=' + searchContent + '&from=' + 0 + '&size=' + 10000 + '',
      method: 'get',
    })
  },
  //查询商品列表
  list(resourceType, selectedScene, selectedStyle, searchContent, from, size) {
    return requestES({
      url: '/search?resourceType=' + resourceType + '&selectedScene=' + selectedScene + '&selectedStyle=' + selectedStyle + '&searchContent=' + searchContent + '&from=' + from + '&size=' + size + '',
      method: 'get',
    })
  },

  //查询商品列表
  getSearchCount(resourceType, selectedScene, selectedStyle, searchContent, from, size) {
    return requestES({
      url: '/SearchCountHandler?resourceType=' + resourceType + '&selectedScene=' + selectedScene + '&selectedStyle=' + selectedStyle + '&searchContent=' + searchContent + '&from=' + from + '&size=' + size + '',
      method: 'get',
    })
  },

  getByID(id) {
    return requestES({
      url: '/get?id=' + id,
      method: 'get',
    })
  },

  getDescByGlobalID(globalID) {
    return requestES({
      url: '/getDesc?globalID=' + globalID,
      method: 'get',
    })
  },
  getStaredVideo() {
    return requestES({
      url: '/GetStaredVideo',
      method: 'get',
    })
  },
  getStaredAudio() {
    return requestES({
      url: '/GetStaredAudio',
      method: 'get',
    })
  },
  getStaredImg() {
    return requestES({
      url: '/GetStaredImg',
      method: 'get',
    })
  },




  // list() {
  //   return requestES({
  //     url: '/search',
  //     method: 'get',
  //     data: {
  //       "scene":"1",
  //       "time":"2",
  //       "style":"3"
  //     }
  //   })
  // }

}


