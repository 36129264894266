import requestTwin from '@/utils/requestTwin'

export default{

  //查询项目列表
  list() {
    return requestTwin({
      url: '/GetUserUsage',
      method: 'post'
    })
  },
  
}