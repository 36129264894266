import request from '@/utils/request'




export default{
 
  //查询订单列表
  list() {
    return request({
      url: '/api/payment-info/list',
      method: 'post'
    })
  },

  listByUser(userId) {
    return request({
      url: '/api/payment-info/listByUser/' + userId,
      method: 'post'
    })
  }
}
