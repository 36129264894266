<template>
  <div class="bg-fa of">
    <section id="index" class="container">
      <header class="comm-title">
        <h2 class="fl tac">
          <span class="c-333">项目列表</span>
        </h2>
      </header>
      <el-table :data="list.slice((currentPage - 1) * PageSize, currentPage * PageSize)" border style="width: 95%">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="proname" label="项目编号" width="230"></el-table-column>
        <el-table-column prop="protype" label="项目类型"></el-table-column>
        <!-- <el-table-column prop="{{ getObsURL(proNum) }}" label="下载链接"></el-table-column> -->
        <!-- <el-table-column prop="prounitprice" label="项目单价">
          <template slot-scope="scope">
            {{ scope.row.prounitprice / 100 }} 元/秒
          </template>
        </el-table-column> -->

        <!-- <el-table-column prop="provideotime" v-if="row.prostatus === '排队中'" label="视频时长(s)">
          <template slot-scope="scope">
            {{ scope.row.proprice / 10 }} 金币
          </template>
        </el-table-column> -->

        <el-table-column prop="proprice" label="项目金额">
          <template slot-scope="scope">
            {{ scope.row.proprice / 10 }} 金币
          </template>
        </el-table-column>

        <!-- <el-table-column label="项目进度">
          <template slot-scope="scope">
          <el-progress :text-inside="true" :stroke-width="20" :percentage="(scope.row.propercentage)"  status="success"></el-progress>
        </template>
        </el-table-column> -->

        <el-table-column label="项目状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.prostatus === '排队中'" type="info">
              {{ scope.row.prostatus }}
            </el-tag>
            <el-tag v-if="scope.row.prostatus === '处理中'" type="info">
              {{ scope.row.prostatus }}
            </el-tag>
            <el-tag v-if="scope.row.prostatus === '处理成功'" type="success">
              {{ scope.row.prostatus }}
            </el-tag>
            <el-tag v-if="scope.row.prostatus === '处理失败'" type="warning">
              {{ scope.row.prostatus }}
            </el-tag>
            <el-tag v-if="scope.row.prostatus === '工程关闭'" type="info">
              {{ scope.row.prostatus }}
            </el-tag>
            <el-tag v-if="scope.row.prostatus === '用户已取消'" type="info">
              {{ scope.row.prostatus }}
            </el-tag>
            <el-tag v-if="scope.row.prostatus === '已退款'" type="warning">
              {{ scope.row.prostatus }}
            </el-tag>
            <el-tag v-if="scope.row.prostatus === '申诉中'" type="warning">
              {{ scope.row.prostatus }}
            </el-tag>
          </template>
        </el-table-column>



        <!-- <el-table-column prop="createTime" label="创建时间"></el-table-column> -->
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.protype !== 'AI音乐' & scope.row.protype !== 'AI音频' & scope.row.protype !== 'AI绘画'  & scope.row.protype !== '在线消除笔' & scope.row.protype !== '在线人像抠图' & scope.row.protype !== '自拍证件照'">
              <el-button v-if="scope.row.prostatus === '处理成功'" type="text" @click="download(scope.row.prosrcvideourl)">下载
              </el-button>
            </div>
            <div v-if="scope.row.protype == 'AI绘画'">
              <el-button v-if="scope.row.prostatus === '处理成功'" type="text"
                @click="getObsURL('Generate/' + scope.row.pronum + '.zip')">下载
              </el-button>
            </div>
            <div v-if="scope.row.protype == 'AI音乐' | scope.row.protype == 'AI音频'">
              <el-button v-if="scope.row.prostatus === '处理成功'" type="text"
                @click="getObsURL('Generate/' + scope.row.pronum + '.mp3')">下载
              </el-button>
            </div>

            <el-button type="text" @click="compalin(scope.row.id)">申诉
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align:center">
        <span>&nbsp;</span>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
        </el-pagination>
      </div>



      <el-dialog title="申诉" :visible.sync="dialogVisible" width="30%">

        <el-form>
          <!-- <h1 class="title">开发票</h1> -->
          <el-form-item label="申诉类型">
            <el-select v-model="comType" placeholder="申诉类型" style="width:100%">
              <el-option v-for="item in complainOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="详细说明">
            <el-input type="text" v-model="complainReason" placeholder="申诉原因详细说明" autocomplete="off"></el-input>
          </el-form-item>


          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="submitComplain()">提交</el-button>
          </el-form-item>
        </el-form>

      </el-dialog>



    </section>

  </div>
</template>

<script>

import projectApi from "../api/project";
import complainApi from "../api/complain";
import ObsClient from 'esdk-obs-browserjs/src/obs'

export default {
  data() {
    return {
      list: [], //订单列表
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 默认每页显示的条数（可修改）
      PageSize: 20,
      comType: '',
      complainOptions: [{
        value: '视频不能下载',
        label: '视频不能下载'
      }, {
        value: '效果不满意',
        label: '效果不满意'
      }, {
        value: '扣款问题',
        label: '扣款问题'
      }, {
        value: '其他',
        label: '其他'
      }],
      complainReason: '',
      dialogVisible: false,
      complainid: '',
    };
  },

  created() {
    //#region 用户检查
    var userName = localStorage.getItem("userName");
    if (userName == "" || userName == null) {
      this.$router.push({ path: "/login" });
    }
    else {
      this.showProjectList()
    }
    //#endregion
  },
  mounted() {
    //#region 用户检查
    var userName = localStorage.getItem("userName");
    if (userName == "" || userName == null) {
      this.$router.push({ path: "/login" });
    }
    else {
      // 每隔1秒定时刷新
      this.timer = setInterval(() => {
        this.showProjectList();
      }, 10000)
    }
  },

  methods: {
    //显示订单列表
    showProjectList() {
      var userId = localStorage.getItem("userId");
      projectApi.list(userId).then((response) => {
        this.list = response.data.list;
        this.totalCount = this.list.length;
      });
    },
    download(videoURL) {
      window.open("http://123.249.75.161/downloadFile/" + videoURL, '_blank')
    },
    compalin(id) {
      this.dialogVisible = true;
      this.complainid = id;
    },

    submitComplain() {
      var ids = this.complainid;
      var complainOptionss = this.comType;
      var complainReasons = this.complainReason;
      try {
        complainApi.submintComplain(ids, complainOptionss, complainReasons);
      } catch (error) {
        console.log(error)
      }
      this.dialogVisible = false;
      this.comType = "";
      this.complainReason = "";
    },

    handleSizeChange(val) {
      // 改变每页显示的条数 
      this.PageSize = val
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.currentPage = 1
    },
    // 显示第几页
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val
    },

    getObsURL(objectKey) {
      var ak = "A8LVRMADNPSSAXOD0CQQ";
      var sk = "mjMVhyVEmdJoP4nLQ0QEcwFl7Le02FrwBwrkanZP";
      var server = "obs.cn-north-4.myhuaweicloud.com";
      const bucketName = 'swdlresource';
      const obsClient = new ObsClient({
        access_key_id: ak,
        secret_access_key: sk,
        server: server
      });

      obsClient.getObject({
        Bucket: bucketName,
        Key: objectKey,
        SaveByType: 'file'
      }).then(function (result) {
        var obszipurl = result.InterfaceResult.Content.SignedUrl;
        window.open(obszipurl, '_self')
      });
      // // 生成带签名的下载链接
      // obsClient.createSignedUrl({
      //   method: 'GET',
      //   bucketName: bucketName,
      //   objectKey: objectKey,
      //   expires: 3600, // 签名有效期，单位为秒
      //   headers: {
      //     'Content-Type': 'text/plain'
      //   }
      // }, function (err, signedUrl) {
      //   if (err) {
      //     console.error('Error:', err);
      //   } else {
      //     console.log('Signed URL:', signedUrl);
      //   }
      // });





    }
  }
};
</script>