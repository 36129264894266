<template>
  <div style="text-align: center;">

    <div class="addreceipt">
      <el-form class="receiptcontainer">
        <!-- <h1 class="title">开发票</h1> -->
        <el-form-item label="可开票金额">
          <label :v-model="restreceiptnum">￥ {{restreceiptnum/100}}</label>
        </el-form-item>
        <el-form-item label="开票金额">
          <el-input type="text" v-model="price" placeholder="开票金额" autocomplete="off" @blur="checkrecpnum();"></el-input>
        </el-form-item>
        <el-form-item label="发票类型">
          <el-select v-model="recpType" placeholder="发票类型" style="width:100%">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="抬头">
          <el-input type="text" v-model="taxhead" placeholder="抬头" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="税号">
          <el-input type="text" v-model="taxnumber" placeholder="税号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮寄人姓名">
          <el-input type="text" v-model="postname" placeholder="邮寄人姓名" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮寄人电话">
          <el-input type="text" v-model="postphone" placeholder="邮寄人电话" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮寄地址">
          <el-input type="text" v-model="postaddress" placeholder="邮寄地址" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%" @click="dosubmit()">提交</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>

import receiptApi from "../api/receipt";
import userApi from "../api/user";

export default {
  data() {
    return {
      price: '',
      recpType: '',
      taxhead: '',
      taxnumber: '',
      postname: '',
      postphone: '',
      postaddress: '',
      restreceiptnum:'',
      options: [{
        value: '增值税普通发票',
        label: '增值税普通发票'
      }, {
        value: '增值税专用发票',
        label: '增值税专用发票'
      }],


    };
  },
  methods: {
    dosubmit: function () {
      var userId = localStorage.getItem("userId");
      receiptApi.addReceipt(this.price*100, this.recpType, this.taxhead, this.taxnumber, this.postname, this.postphone, this.postaddress, userId).then(response => {
        if (response.code === 0) {
          // 三秒后跳转到订单列表
          setTimeout(() => {
            this.$message({
              message: '发票申请成功',
              type: 'success'
            });

          }, 1000);
        }
      });
    },
    checkrecpnum()
    {
      if(this.price>(this.restreceiptnum/100))
      {
        this.$message({
              message: '开票金额不得大于可开票金额',
              type: 'warning'
            });
        this.price =0;
      }
    }
  },

  created() {
    var userName = localStorage.getItem("userName");
    if (userName == "" || userName == null) {
      this.$router.push({ path: "/login" });
    }
    else
    {

    var userId =localStorage.getItem("userId");

    userApi.queryUserByID(userId).then((response) => {
        this.restreceiptnum = response.data.userInfo.restreceiptnum;
      });
    }






  },
}

</script>

<style >
.addreceipt {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.receiptcontainer {
  border-radius: 10px;
  margin: 0px auto;
  width: 350px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}
</style>