<template>
  <div style=" text-align: center;">
    <section id="index" class="container">
      <div id="videoaprocessdiv" :style="{ display: videoaprocessdivVisible }">
        <p>&nbsp;</p>
        <div :style="{ display: uploadFormVisible }">
          <el-upload class="upload-demo" :action="upurl" :http-request="handleHttpRequest">
            <el-button class="upload-demo-btn"  size="middle" type="primary">点击上传</el-button>
            <div>&nbsp;</div>
          </el-upload>
          <video style="height:auto;width: 1100px;" src="../../../public/static/video/shipinbiaozhuangao.mp4"
            id="videoctrl" controls="controls"></video>
        </div>
        <div id="videoDiv" :style="{ display: videoVisible }">
          <div>
            <br />
            <div style="position:relative;overflow: auto;">
              <div style="text-align:center;border: 1px;">
                <div id="canvasContent" style="position: absolute;z-index: 999;"></div>
                <div>
                  <table style="width:100%">
                    <tr>
                      <td style="width:70%">
                        <!-- <video src="" id="videoctrl2" controls="controls"></video> -->
                        <video ref="videoPlayer" v-if="videoURL" controls>
                          <source :src="videoURL" type="video/mp4">
                        </video>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p></p>
        <p></p>
        <p>&nbsp;</p>
        <div id="nextB" :style="{ display: nextVisible }">
          <el-button type="primary" @click="dialogFormVisible = true; checkProType();" class="upload-demo-btn">下一步</el-button>
        </div>
        <p>&nbsp;</p>
        <el-dialog title="支付方式" :visible.sync="dialogFormVisible" width="30%" style="text-align:left">
          <el-row>
            <el-col :span="12">
              <div>时长：{{ videoTime }}</div>
            </el-col>
            <el-col :span="12">
              <div>价格：￥{{ totalPrice / 100 }}金币 </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div>计价规则：￥{{ unitPrice / 100 }}金币/秒，不足一秒按一秒计算，超过按实际时长计算</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="width:100%">
              <el-radio style="width:100%" v-model="radioPayType" label="1" border>
                金币支付&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可用金币：{{ balance / 100 }} 币</el-radio>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="width:100%">
              <el-radio style="width:100%" v-model="radioPayType" disabled label="2" border>
                微信支付&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;总计价格：￥{{ totalPrice / 100 }}元</el-radio>
            </el-col>
          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false; donext()">确认支付</el-button>
          </div>
        </el-dialog>
        <el-dialog title="请选择处理类型" :visible.sync="typeDialogFormVisible" width="300px">
          <el-select v-model="proType" placeholder="请选择">
            <el-option v-for="item in projectTypeList" :key="item.protype" :label="item.protype" :value="item.protype"
              :disabled="item.disabled">
            </el-option>
          </el-select>
          <p>&nbsp;</p>
          <el-button @click="typeDialogFormVisible = false" width="300px">确 定</el-button>
        </el-dialog>
      </div>
    </section>
  </div>
</template>
  
<script>
import request from '@/utils/request'
import userApi from "../../api/user";
import projectAPi from '../../api/project';
import projectTypeApi from "../../api/projectType";
export default {
  data() {
    return {
      activeName: 'first',
      billDate: '',
      dialogFormVisible: false,
      typeDialogFormVisible: false,
      uploadFormVisible: "block",
      videoVisible: "none",
      nextVisible: "none",
      projectTypeList: [],
      radioPayType: '1',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      formLabelWidth: '120px',
      balance: '',
      videoTime: '',
      unitPrice: '',
      proType: '视频标清转高清',
      totalPrice: 0,
      projectID: '',
      videoaprocessdivVisible: "block",
      drawctrlDivVisible: "none",
      upurl: 'https://www.swdl.net.cn:8090/api/test/upload',
      canvas: "",
      ctx: "",
      ctxX: 0,
      ctxY: 0,
      lineWidth: 2,
      type: "R",
      canvasHistory: [],
      lineHistory: [],
      step: 0,
      loading: false,
      fillStyle: "#CB0707",
      strokeStyle: "#CB0707",
      img: new Image(),
      videoLeft: 0,
      videoTop: 0,
      videoURL: "",
      value1: '标清转高清',
      value2: '普通视频'
    }
  },


  created() {
    var userName = localStorage.getItem("userName");
    if (userName == "" || userName == null) {
      // this.$router.push({ path: "/login" });
    }
    else {
      this.queryUserInfo()
      projectTypeApi.list().then((response) => {
        this.projectTypeList = response.data.projectTypeList;
      });
    }
  },


  methods: {
    getObjectURL(file) {
      var url = null;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        let binaryData = [];
        binaryData.push(file);
        url = window.URL.createObjectURL(new Blob(binaryData));
      } else if (window.webkitURL != undefined) {
        let binaryData = [];
        binaryData.push(file);
        url = window.URL.createObjectURL(new Blob(binaryData));
      }
      return url;
    },
    videoShow(file) {
      const fileObj = this.getObjectURL(file.file);
      this.videoURL = fileObj
    },
    handleHttpRequest(file) {
      var userName = localStorage.getItem("userName");
      if (userName == "" || userName == null) {
        this.$showWarning("您还没有登录，请登录后再使用")
      }
      else {
        const formData = new FormData()
        formData.append('file', file.file)
        var userId = localStorage.getItem("userId")
        formData.append('projectType', this.proType)
        formData.append('userId', userId)

        request.post(this.upurl, formData).then(data => {
          this.videoTime = data.videoTime
          this.proType = data.proType
          this.unitPrice = data.unitPrice
          this.totalPrice = data.totalPrice
          this.projectID = data.proID
          this.videoShow(file)
          this.nextVisible = "block"
          this.videoVisible = "block"
          this.uploadFormVisible = "none"
        }).catch(response => {
          console.log(response)
        })
      }


    },
    donext() {
      if (this.balance < this.totalPrice) {
        this.BalanceNotice()
        this.timer = setTimeout(() => {
          this.$router.push({ path: "/User?tab=charge" });
        }, 3000);
      }
      else {
        projectAPi.payProject(this.projectID, this.lineHistory).then((response) => {
          if (response.code === 0) {
            projectTypeApi.list().then((response) => {
              this.projectTypeList = response.data.projectTypeList;
            });

            alert("项目创建成功，请到用户中心查看项目")
            this.timer = setTimeout(() => {
              this.$router.push({ path: "/User?tab=project" });
            }, 1000);


          }
          else {
            this.BalanceNotice()
            this.timer = setTimeout(() => {
              this.$router.push({ path: "/User" });
            }, 3000);
          }
        })
      }
    },

    queryUserInfo() {
      userApi.queryUserByID(localStorage.getItem("userId")).then((response) => {
        this.balance = response.data.userInfo.balance;
      })
    },

    BalanceNotice() {
      this.$message({
        message: '余额不足，即将为您跳转到充值页面',
        type: 'warning'
      });
    },

    checkProType() {
      if (this.proType == "" || this.proType == null) {
        this.typeDialogFormVisible = true;
      }
    },
    toWorkSpace(projectType) {
      this.videoaprocessdivVisible = "block";
      this.proType = projectType;
    },
  },

  watch: {
    value1: function () {
      if (this.value1 == "标清转高清" && this.value2 == "普通视频") {
        this.projectType = "视频标清转高清"
      }
      if (this.value1 == "标清转高清" && this.value2 == "动漫") {
        this.projectType = "视频高清转4K"
      }
      if (this.value1 == "高清转4K" && this.value2 == "普通视频") {
        this.projectType = "动漫标清转高清"
      }
      if (this.value1 == "高清转4K" && this.value2 == "动漫") {
        this.projectType = "动漫高清转4K"
      }
      this.toWorkSpace(this.projectType)
    },
    value2: function () {
      if (this.value1 == "标清转高清" && this.value2 == "普通视频") {
        this.projectType = "视频标清转高清"
      }
      if (this.value1 == "标清转高清" && this.value2 == "动漫") {
        this.projectType = "视频高清转4K"
      }
      if (this.value1 == "高清转4K" && this.value2 == "普通视频") {
        this.projectType = "动漫标清转高清"
      }
      if (this.value1 == "高清转4K" && this.value2 == "动漫") {
        this.projectType = "动漫高清转4K"
      }
      this.toWorkSpace(this.projectType)
    }
  }
}
</script>