<template>
  <div  style="text-align:center">
    <header class="comm-title">
        <h2 class="fl tac">
          <span class="c-333">本次项目处理需要扣除您￥50，您的余额为￥{{balance/100}}，请选择下一步继续</span>
        </h2>
      </header>
      <el-button type="primary"    @click="dosubmit()">下一步</el-button>
  </div>
</template>
 
<script>
import userApi from "../api/user";

export default {
  name: "login",
  data: function () {
    return {
      username: "admin",
      password: "111111",
      balance:""
    };
  },

  //页面加载时执行
  created() {
    //#region 用户检查
    var userName = localStorage.getItem("userName");
    if (userName == "" || userName == null) {
      this.$router.push({ path: "/login" });
    }
    //#endregion

    this.queryUserInfo()
  },

  
  methods: {
    dosubmit: function () {
      // let params = {
      //   username: this.username,
      //   password: this.password
      // };

      userApi.login(this.username, this.password).then((response) => {
        console.log("查询用户信息：" + response.code);
        if (response.code === 0) {
          setTimeout(() => {
            localStorage.setItem("userName", this.username);
            localStorage.setItem("passWord", this.password);
            localStorage.setItem("userId", response.data.userInfo.id)
            this.$router.push({ path: "/ProjectSuccess" });
            this.$router.go(0)
          }, 1000);
        }
        else
        {
          this.$router.push({ path: "/Login" });
        }
      });
    },


    queryUserInfo() {
      userApi.queryUserByID(localStorage.getItem("userId")).then((response) => {
        this.balance = response.data.userInfo.balance;
      })
    },
  },
};
</script>
