import request from '@/utils/request'

export default{

  submintComplain(projectID,complainOptionss,complainReasons){
    return request({
      url: '/api/complain/AddComplain/'+projectID+'/'+complainOptionss+'/'+complainReasons,
      method: 'post'
    })
  },
  
}