<template>
    <div style="text-align: center;" class="centered">
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                    <h2>AI音频制作</h2>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="1">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
            <el-col :span="22">
                <div class="grid-content bg-purple-light">
                    <div class="paintTitle" style="margin-top: 15px;text-align: left;">
                        <div class="paintTitleText">音频创意：</div>
                        <div class="introduce">
                            <el-input placeholder="输入一段文本，AI将实现您的创意" v-model="text">
                            </el-input>
                            <label style="font-size: small;margin-top: 5px;color: rgba(173, 170, 170, 0.808);">示例：小狗叫声 |
                                恐怖的森林 | 轮船鸣笛的声音</label>
                        </div>
                    </div>
                    <div class="paintTitle" style="text-align: left;">
                        <div class="paintTitleText">音频时长：</div>
                        <template>
                            <!-- <el-input-number v-model="num" controls-position="right" @change="handleChange" :min="1"
                                :max="60"></el-input-number> -->
                            <template>
                                <div class="block">
                                    <el-slider v-model="duration" show-input style="width: 800px;" :min=1 :max=60>
                                    </el-slider>
                                </div>
                            </template>
                        </template>
                    </div>

                    <div>
                        <br />
                        <el-button slot="append" icon="el-icon-s-promotion" class="upload-demo-btn"
                            style="width: 200px; font-size:large;" type="primary" size="medium" @click="getAudio">
                            立 即 创 作
                        </el-button>
                        <br />
                    </div>
                </div>
            </el-col>
            <el-col :span="1">
                <!-- <div class="grid-content bg-purple"></div> -->
            </el-col>
        </el-row>
        <!-- <br /> -->
        <center>
            <table style="text-align: center;" :width="tableWitdh"   v-show="audioResultShow" >
                <tr>
                    <td style="text-align: center;" >
                        <audio controls id="audioCtrl2">
                            <source type="audio/mpeg">
                            Your browser does not support this audio format.
                        </audio>
                    </td>
                    <td></td>
                </tr>

                <tr>
                    <td style="text-align: center;">
                        <el-button v-on:click="saveImg" type="primary"  class="upload-demo-btn" >下载</el-button>
                    </td>
                    <td></td>
                </tr>
            </table>
        </center>

    </div>
</template>
<script>
// import axios from 'axios'
import requestAIAudio from '@/utils/requestAIAudio'
// import request from '@/utils/request'
import userApi from "../../api/user";
// import projectAPi from '../../api/project'
import { Loading } from 'element-ui';
export default {
    data() {
        return {
            text: "",
            duration: 10,
            wav_id: "0",
            audioResultShow: false,
            baiduloading: false,
            tableWitdh: 0,
            timerId: null,
            base64Data: ""
        }
    },
    methods: {
        getBaiduImg() {
            if (this.text == "")
                return;
            Loading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.1)' });
            this.baiduloading = true
            var userid = localStorage.getItem("userId");
            var waveid = Date.now().toString()
            var content = { "user_id": userid, "wav_id": waveid, "descriptions": this.text, "duration": this.duration, "op": "audiogen" }
            requestAIAudio({
                url: '/main',
                data: JSON.stringify(content),
                method: 'post'
            }).then(response => {
                console.log(response.code)
                if (response.code == "0") {
                    this.wav_id = waveid
                    this.startTimer()
                }
            }).catch(error => {
                console.log(error)
            })
        },

        getAudioResult() {
            if (this.wav_id == "0")
                return;

            var userid = localStorage.getItem("userId");
            var content = { "user_id": userid, "wav_id": this.wav_id.toString(), "op": "audiogen" }
            requestAIAudio({
                url: '/result',
                data: JSON.stringify(content),
                method: 'post'
            }).then(response => {
                if (response.data.state == 1) {
                    this.base64Data = response.data.wavebase64;
                    var base64A = "data:audio/mpeg;base64," + this.base64Data;
                    document.getElementById("audioCtrl2").src = base64A
                    this.stopTimer()
                    this.wav_id = "0";
                    this.audioResultShow = true;
                    this.baiduloading = false;
                    if (!this.baiduloading) {
                        let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.1)' });
                        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                            loadingInstance.close();
                        });
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        },


        getAudio() {
            var userid = localStorage.getItem("userId");
            if (userid == "" || userid == null) {
                this.$showWarning("您还没有登录，请登录后再使用")
            }
            else {
                //确认是否扣除
                var result = confirm("本次操作将扣除您1金币，您确定要执行此操作吗？");
                if (result) {
                    // 用户点击了确认按钮
                    userApi.queryUserByID(userid).then((response) => {
                        if (response.code === 0 && response.data.userInfo != null) {
                            if (response.data.userInfo.balance > 10) {
                                this.getBaiduImg();
                                // //创建工程
                                // request({
                                //     url: '/api/test/upload2/AI音频/' + localStorage.getItem("userId"),
                                //     method: 'post'
                                // }).then(response2 => {
                                //     var projectID = response2.proID
                                //     projectAPi.payProject(projectID, null).then(() => {
                                //     })
                                // })
                            }
                            else {
                                alert("余额不足，请至用户中心充值")
                            }
                        }
                    })
                }
            }
        },
        startTimer() {
            // Use setInterval to execute a function every second
            this.timerId = setInterval(() => {
                this.getAudioResult();
            }, 1000)
        },
        stopTimer() {
            // Use clearInterval to stop the timer
            clearInterval(this.timerId)
        },
        base64ToBlob(base64) {
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return new Blob([bytes], { type: 'music/mp3' }); // Change the type accordingly
        },
        saveBlobAsFile(blob, fileName) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        },
        saveBase64Img(base64String) {
            const blob = this.base64ToBlob(base64String);
            this.saveBlobAsFile(blob, 'music.mp3'); // Replace with your desired file name
        },

        saveImg() {
            this.saveBase64Img(this.base64Data)
        },
    },
    created() {
        this.tableWitdh = window.innerWidth * 0.5; // adjust the percentage as needed
    }
}

</script>

<style scoped>
html,
body {
    height: 100%;
}

.centered {
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.paintTitle {
    margin: 20px 0;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
}

.paintTitleText {
    display: flex;
    margin-right: 10px;
}

.introduce {
    display: flex;
    flex-direction: column;
    width: 800px;
}

input {
    width: 800px;
}
</style>