<template>
  <div>

    <div style="text-align: right;;" v-if="show1">
      <el-button  @click="freeStyle" type="primary">
        编辑
      </el-button>
    </div>
    <table>
      <tr v-if="show1">
        <td>
          <iframe src="/static/personMatting/index.html" id="imgEditorFrame"
            style="width: 100vw; height: 100vh;" scrolling="no"></iframe>
        </td>
      </tr>
      <tr v-if="show2">
        <td>
          <iframe src="/static/personMatting/freeStyle.html" id="freeFrame"
            style="width: 100vw; height: 100vh;" scrolling="no"></iframe>
        </td>
      </tr>
    </table>

  </div>
</template>
<script>
export default {
  methods: {
    freeStyle: function () {
      this.show1 = false
      this.show2 = true
    }
  },
  data: function () {
    return {
      show1: true,
      show2: false,
    };
  },
}


</script>