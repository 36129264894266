<template>
    <div style="text-align: center;" class="centered">
        <!-- <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                    <h3>AI绘画</h3>
                </div>
            </el-col>
        </el-row> -->
        <el-row>
            <el-col :span="1">
                <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
            <el-col :span="22">
                <div class="grid-content bg-purple-light">
                    <div class="paintTitle" style="margin-top: 15px;text-align: left;">
                        <div class="paintTitleText">图片创意：</div>
                        <div class="introduce">
                            <el-input placeholder="输入一段话，AI将实现您的创意" v-model="text">
                            </el-input>
                            <label style="font-size: small;margin-top: 5px;color: rgba(173, 170, 170, 0.808);">示例：一只昂首挺胸的大公鸡
                                |或| 雨中的荷花 |或| 一个撑着油纸伞的姑娘</label>
                        </div>

                    </div>
                    <!-- <div style="text-align: left;">
                        <h4></h4>
                    </div> -->
                    <div class="paintTitle" style="text-align: left;">
                        <div class="paintTitleText">图片尺寸：</div>
                        <el-radio-group v-model="resolution">
                            <el-radio-button label="1024*1024"></el-radio-button>
                            <el-radio-button label="1024*1536"></el-radio-button>
                            <el-radio-button label="1536*1024"></el-radio-button>
                        </el-radio-group>
                    </div>
                    <!-- <div style="text-align: left;">
                        <h4>图片风格：</h4>
                    </div> -->
                    <!-- <p>&nbsp;</p> -->
                    <div class="paintTitle" style="text-align: left;">
                        <div class="paintTitleText">图片风格：</div>
                        <el-radio-group v-model="style">
                            <el-radio-button label="写实风格"></el-radio-button>
                            <el-radio-button label="探索无限"></el-radio-button>
                            <el-radio-button label="古风"></el-radio-button>
                            <el-radio-button label="二次元"></el-radio-button>
                            <el-radio-button label="浮世绘"></el-radio-button>
                            <el-radio-button label="low poly"></el-radio-button>
                            <el-radio-button label="未来主义"></el-radio-button>
                            <el-radio-button label="像素风格"></el-radio-button>
                            <el-radio-button label="概念艺术"></el-radio-button>
                            <br />
                            <el-radio-button label="赛博朋克"></el-radio-button>
                            <el-radio-button label="洛丽塔风格"></el-radio-button>
                            <el-radio-button label="巴洛克风格"></el-radio-button>
                            <el-radio-button label="超现实主义"></el-radio-button>
                            <el-radio-button label="水彩画"></el-radio-button>
                            <el-radio-button label="蒸汽波艺术"></el-radio-button>
                            <el-radio-button label="油画"></el-radio-button>
                            <el-radio-button label="卡通画"></el-radio-button>
                        </el-radio-group>
                    </div>
                    <div>
                        <br />
                        <el-button slot="append" icon="el-icon-s-promotion" class="upload-demo-btn"
                            style="width: 200px; font-size:large;" type="primary" size="medium" @click="beginCreate">
                            立 即 创 作
                        </el-button>
                        <br />
                    </div>
                </div>
            </el-col>
            <el-col :span="1">
                <!-- <div class="grid-content bg-purple"></div> -->
            </el-col>
        </el-row>
        <!-- <br /> -->
        <center>
            <table style="text-align: center;" :width="tableWitdh">
                <tr>
                    <td><img :src="base64ImgData2" :width="tableWitdh * 0.5"></td>
                    <td>&nbsp;</td>
                    <td><img :src="base64ImgData3" :width="tableWitdh * 0.5"></td>
                </tr>
                <tr style="text-align: center;">
                    <td style="text-align: center;">
                        <el-button slot="append" icon="el-icon-s-promotion" class="upload-demo-btn"
                            style="width: 200px; font-size:large;" size="medium" type="primary" v-on:click="saveImg2"
                            v-show="baiduButtonShow">下载</el-button>
                    </td>
                    <td></td>
                    <td style="text-align: center;"><el-button v-on:click="saveImg3" class="upload-demo-btn"
                            v-show="baiduButtonShow" slot="append" style="width: 200px; font-size:large;" size="medium"
                            type="primary">下载</el-button></td>
                </tr>
                <br /><br />

            </table>
        </center>

    </div>
</template>
<script>
// import axios from 'axios'
import requestGPT from '@/utils/requestGPT'
import request from '@/utils/request'
import userApi from "../../api/user";
import projectAPi from '../../api/project'
import { Loading } from 'element-ui';
export default {
    data() {
        return {
            text: "",
            resolution: "1024*1024",
            style: "写实风格",
            num: 2,
            taskId: 0,
            base64ImgData2: '',
            base64ImgData3: '',
            baiduButtonShow: false,
            baiduloading: false,
            tableWitdh: 0,
            timerId: null
        }
    },
    methods: {
        getBaiduImg() {
            if (this.text == "")
                return;
            Loading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.1)' });
            this.baiduloading = true
            var content = { "text": this.text, "resolution": this.resolution, "style": this.style, "num": this.num,"platform":"saas" }
            requestGPT({
                url: '/BaiduPaintQueryHandler',
                data: JSON.stringify(content),
                method: 'post'
            }).then(response => {
                if (response.data.taskId > 0) {
                    this.taskId = response.data.taskId
                    this.startTimer()

                    this.createProject()
                }
            }).catch(error => {
                console.log(error)
            })
        },

        getBaiduImgResult() {
            if (this.taskId == 0)
                return;
            var content = { "taskId": this.taskId }
            requestGPT({
                url: '/BaiduPaintResultHandler',
                data: JSON.stringify(content),
                method: 'post'
            }).then(response => {
                if (response.data.imgUrls.length > 0) {
                    this.base64ImgData2 = response.data.imgUrls[0].image;
                    this.base64ImgData3 = response.data.imgUrls[1].image;
                    this.stopTimer()
                    this.taskId = 0;
                    this.baiduButtonShow = true;
                    this.baiduloading = false;

                    if (!this.stabilityloading && !this.baiduloading) {
                        let loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.1)' });
                        this.$nextTick(() => {
                            loadingInstance.close();
                        });
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        },
        createProject() {
            //创建工程
            var userid = localStorage.getItem("userId");
            request({
                url: '/api/test/upload2/AI绘画/' + userid + '/' + this.text + "/" + this.taskId,
                method: 'post'
            }).then(response2 => {
                // alert(response2)
                var projectID = response2.proID
                projectAPi.payProject(projectID, null).then(() => {
                })
            })
        },


        beginCreate() {
            var userid = localStorage.getItem("userId");
            if (userid == "" || userid == null) {
                this.$showWarning("您还没有登录，请登录后再使用")
            }
            else {
                //确认是否扣除
                var result = confirm("本次操作将扣除您1金币，您确定要执行此操作吗？");
                if (result) {
                    // 用户点击了确认按钮
                    userApi.queryUserByID(userid).then((response) => {
                        if (response.code === 0 && response.data.userInfo != null) {
                            if (response.data.userInfo.balance > 10) {
                                this.getBaiduImg();

                            }
                            else {
                                alert("余额不足，请至用户中心充值")
                            }
                        }
                    })
                }
            }
        },
        startTimer() {
            this.timerId = setInterval(() => {
                this.getBaiduImgResult();
            }, 1000)
        },
        stopTimer() {
            clearInterval(this.timerId)
        },




        base64ToBlob(base64) {
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return new Blob([bytes], { type: 'image/png' }); // Change the type accordingly
        },
        saveBlobAsFile(blob, fileName) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        },
        saveBase64Img(base64String) {
            const blob = this.base64ToBlob(base64String);
            this.saveBlobAsFile(blob, 'image'); // Replace with your desired file name
        },
        saveSrcImg(imgSrc) {
            fetch(imgSrc)
                .then(res => res.blob().then(blob => {
                    const a = document.createElement('a'),	// 动态创建a标签，防止下载大文件时，用户没看到下载提示连续点击
                        url = window.URL.createObjectURL(blob),
                        filename = 'image';
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }));
        },

        saveImg2() {
            this.saveSrcImg(this.base64ImgData2)
        },
        saveImg3() {
            this.saveSrcImg(this.base64ImgData3)
        },
    },
    created() {
        this.tableWitdh = window.innerWidth * 0.5; // adjust the percentage as needed
    }
}

</script>

<style scoped>
html,
body {
    height: 100%;
}

.centered {
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.paintTitle {
    margin: 20px 0;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
}

.paintTitleText {
    display: flex;
    margin-right: 10px;
}

.introduce {
    display: flex;
    flex-direction: column;
    width: 800px;
}

input {
    width: 800px;
}</style>