<template>
    <div>
        <section id="userBalance" class="container">
            <header class="comm-title">
                <h2>
                    <span class="c-333">您是 {{ vipname }} 用户</span>&nbsp;&nbsp;&nbsp;&nbsp;到期时间{{ vipendtime
                    }}&nbsp;&nbsp;&nbsp;&nbsp;<span class="c-333">金币数 {{
    balance / 10 }} 个</span>
                </h2>

                <!-- <h2>
                    
                </h2> -->
            </header>
        </section>
        <section>
            <header class="comm-title">
                <h2 class="fl tac">
                    <span class="c-333">VIP申请</span>
                </h2>
            </header>
            <ul>
                <table border="1px" style="text-align: center;width: 80%;">
                    <tr>
                        <td>特权对比</td>
                        <td v-for="product in vipProductList" :key="product.id">
                            <a :class="[
                                'orderBtn',
                                { current: payOrder.productId === product.id },
                            ]" @click="selectItem(product.id)" href="javascript:void(0);">
                                {{ product.title }}

                                ¥{{ product.price / 100 }}
                            </a>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td><br /></td>
                        <td></td>
                        <td></td>

                    </tr> -->
                    <tr class="trRow">
                        <td></td>
                        <td><el-button :disabled="payVIPBtnDisabled" type="warning" class="payBtn" round
                                style="height: 44px; font-size: 18px;" @click="toPayVIP(9)">
                                立即开通
                            </el-button></td>
                        <td><el-button :disabled="paySVIPBtnDisabled" type="warning" class="payBtn" round
                                style="height: 44px; font-size: 18px" @click="toPayVIP(10)">
                                立即开通
                            </el-button></td>
                    </tr>
                    <tr>
                        <td><br /></td>
                        <td></td>
                        <td></td>

                    </tr>
                    <tr class="trRow">
                        <td>VIP活动</td>
                        <td>赠送1000金币</td>
                        <td>赠送5000金币</td>
                    </tr>
                    <tr>
                        <td><br /></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr class="trRow">
                        <td>授权期限</td>
                        <td>永久授权</td>
                        <td>永久授权</td>
                    </tr>
                    <tr>
                        <td><br /></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr class="trRow">
                        <td rowspan="3">下载数量</td>
                        <td>10个视频/天</td>
                        <td>30个视频/天</td>
                    </tr>
                    <tr class="trRow">
                        <td>20个音频/天</td>
                        <td>50个视频/天</td>
                    </tr>
                    <tr class="trRow">
                        <td>50个音频/天</td>
                        <td>100个视频/天</td>
                    </tr>
                    <tr>
                        <td><br /></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr class="trRow">
                        <td>下载大小</td>
                        <td>1GB/天</td>
                        <td>10GB/天</td>
                    </tr>
                </table>

            </ul>
        </section>
        <div>
            <el-dialog :visible.sync="codeDialogVisible" :show-close="false" @close="closeDialog" width="350px" center>
                <qriously :value="codeUrl" :size="300" />
                使用微信扫码支付
            </el-dialog>
        </div>

    </div>
</template>
<script>
import productApi from "../../api/product";
import wxPayApi from "../../api/wxPay";
import orderInfoApi from "../../api/orderInfo";
import userApi from '../../api/user';

export default {
    data() {
        return {
            payVIPBtnDisabled: false, //确认支付按钮是否禁用
            paySVIPBtnDisabled: false, //确认支付按钮是否禁用
            codeDialogVisible: false, //微信支付二维码弹窗
            chargeProductList: [], //商品列表
            vipProductList: [],
            payOrder: {
                //订单信息
                productId: "", //商品id
                payType: "wxpay", //支付方式
                userId: "",
            },
            codeUrl: "", // 二维码
            orderNo: "", //订单号
            timer: null, // 定时器
            balance: "",
            vipname: "",
            vipendtime: ""
        };
    },
    created() {
        //#region 用户检查
        var userName = localStorage.getItem("userName");
        if (userName == "" || userName == null || userName == "null") {
            this.$router.push({ path: "/login" });
        }
        else {
            //获取商品列表
            productApi.list().then((response) => {
                this.productList = response.data.productList;


                this.chargeProductList = this.productList.filter((item) => {
                    return item.producttype == '充值'
                })
                this.payOrder.productId = this.chargeProductList[0].id;


                this.vipProductList = this.productList.filter((item) => {
                    return item.producttype == 'SAASVIP'
                })

                this.payOrder.userId = localStorage.getItem("userId");

            });
            this.queryUserInfo()
        }

        var tab = this.$route.query.tab;
        if (tab == "charge") {
            this.activeName = "third";
        }

        //#endregion
    },
    methods: {
        queryUserInfo() {
            userApi.queryUserByID(localStorage.getItem("userId")).then((response) => {
                this.balance = response.data.userInfo.balance;
                this.vipname = response.data.userInfo.vipname;


                this.vipendtime = response.data.userInfo.vipendtime;
                const myDate = new Date(this.vipendtime);
                const formattedDate = myDate.toLocaleDateString();
                this.vipendtime = formattedDate;



                if (this.vipname == "VIP") {
                    this.payVIPBtnDisabled = true
                }
                else if (this.vipname == "SVIP") {
                    this.payVIPBtnDisabled = true
                    this.paySVIPBtnDisabled = true
                }
            })
        },
        //确认支付
        toPayVIP(productId) {
            this.payOrder.payType = 'wxpay';
            if (localStorage.getItem("userId") == "") {
                this.$router.push({ path: "/login" });
            } else {
                //禁用按钮，防止重复提交
                this.payVIPBtnDisabled = true;
                this.paySVIPBtnDisabled = true;

                //微信支付
                if (this.payOrder.payType === "wxpay") {
                    //调用统一下单接口
                    wxPayApi.nativePay(productId, this.payOrder.userId).then((response) => {
                        this.codeUrl = response.data.codeUrl;
                        this.orderNo = response.data.orderNo;

                        //打开二维码弹窗
                        this.codeDialogVisible = true;

                        //启动定时器
                        this.timer = setInterval(() => {
                            //查询订单是否支付成功
                            this.queryOrderStatus();
                        }, 300);
                    });
                }
            }
        },
        // 查询订单状态
        queryOrderStatus() {
            orderInfoApi.queryOrderStatus(this.orderNo).then((response) => {
                console.log("查询订单状态：" + response.code);
                // 支付成功后的页面跳转
                if (response.code === 0) {
                    clearInterval(this.timer);
                    this.$message({
                        message: '支付成功，即将为您跳转到用户首页',
                        type: 'success'
                    });
                    // 三秒后跳转到订单列表
                    setTimeout(() => {
                        location.reload();
                        // this.$router.push({ path: "/User" });
                    }, 2000);
                }
            });
        },
        //关闭微信支付二维码对话框时让“确认支付”按钮可用
        closeDialog() {
            // this.payVIPBtnDisabled = false;
            // this.paySVIPBtnDisabled = false;


            if (this.vipname == "VIP") {
                this.payVIPBtnDisabled = true
                this.paySVIPBtnDisabled = false;
            }
            else if (this.vipname == "SVIP") {
                this.payVIPBtnDisabled = true
                this.paySVIPBtnDisabled = true
            }


            clearInterval(this.timer);
        },
    }
}

</script>
<style scoped>
.trRow {
    height: 30px;
    /* border-bottom: 1px solid #7a78785b !important; */
    padding: 10px 0;
    margin: 30px 0;;
}
.payBtn {
    color: #4e73f0;
}
.payBtn:hover {
    color: #fff;
}
</style>