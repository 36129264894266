<template>
  <div class="login-wrap">
    <el-form class="login-container">
      <h1 class="title">绑定账户</h1>
      <el-form-item label="账号">
        <el-input type="text" v-model="userName" placeholder="账号" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="昵称">
        <el-input type="text" v-model="nickName" placeholder="昵称" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input type="password" v-model="passWord" placeholder="密码" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" style="width: 100%" @click="dosubmit()">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
 
<script>
import userApi from "../api/user";

export default {
  name: "register",
  data: function () {
    return {
      userName: "",
      nickName: "",
      passWord: "",
    };
  },
  methods: {
    dosubmit: function () {
      userApi
        .register(this.userName, this.nickName, this.passWord)
        .then((response) => {
          console.log("注册用户信息：" + response.code);

          // 支付成功后的页面跳转
          if (response.code === 0) {
            // 三秒后跳转到订单列表
            setTimeout(() => {
              localStorage.setItem("username", this.username);
              this.$router.push({ path: "/Login" });
              this.$router.go(0);
            }, 1000);
          }
        });
    },
  },
};
</script>

<style scoped>
.login-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.login-container {
  border-radius: 10px;
  margin: 0px auto;
  width: 350px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}
</style>