<template>
    <div>
        <el-tabs v-model="activeName" type="card" tab-position="left" style="width: 98vw;height: 120vh;"
            @tab-click="handleClick">
            <el-tab-pane label="AI绘画" name="first">
                <Text2Img name="Text2Img" />
            </el-tab-pane>
        </el-tabs>
        <el-dialog title="支付方式" :visible.sync="dialogFormVisible" width="30%" style="text-align:left">

            <el-row>

                <el-col :span="24">
                    <div>价格：￥{{ 10 / 100 }}金币 </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <div>计价规则：￥{{ 10 / 100 }}金币/次</div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" style="width:100%">
                    <el-radio style="width:100%" v-model="radioPayType" label="1" border>
                        金币支付&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可用：￥{{ balance / 100 }}金币</el-radio>
                    <!-- <el-radio style="width:100%" v-model="radioPayType" disabled label="2" border>微信支付&nbsp;&nbsp;&nbsp;&nbsp;总计价格：￥{{ totalPrice/100}}元</el-radio> -->
                </el-col>
            </el-row>
            <!-- <el-row>
                <el-col :span="24" style="width:100%">
                    <el-radio style="width:100%" v-model="radioPayType" disabled label="2" border>
                        微信支付&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;总计：￥{{ totalPrice / 100 }}金币</el-radio>
                </el-col>
            </el-row> -->

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false; donext()">确认支付</el-button>
            </div>
        </el-dialog>


    </div>
</template>
<script>
import Text2Img from './Text2Img.vue'
// import removePen from './removePen.vue';
// import faceMatting from './faceMatting.vue'
// import personMatting from './personMatting.vue'



// import request from '@/utils/request'
import userApi from "../../api/user";
import projectAPi from '../../api/project';
import projectTypeApi from "../../api/projectType";
export default {
    components: {
        // removePen, faceMatting,personMatting
        Text2Img
    },
    data() {
        return {
            activeName: 'first',
            dialogFormVisible: false,
            balance: '',
            radioPayType: '1',
        }
    },
    created() {
    },
    methods: {
        handleClick() {
        },

        queryUserInfo() {
            userApi.queryUserByID(localStorage.getItem("userId")).then((response) => {
                this.balance = response.data.userInfo.balance;
            })
        },
        donext() {
            if (this.balance < this.totalPrice) {
                this.BalanceNotice()
                this.timer = setTimeout(() => {
                    this.$router.push({ path: "/User?tab=charge" });
                }, 2000);
            }
            else {
                projectAPi.payProject(this.projectID, this.lineHistory).then((response) => {
                    if (response.code === 0) {
                        projectTypeApi.list().then((response) => {
                            this.projectTypeList = response.data.projectTypeList;
                        });
                        this.activeName = "second"
                    }
                    else {
                        this.BalanceNotice()
                        this.timer = setTimeout(() => {
                            this.$router.push({ path: "/User" });
                        }, 2000);
                    }
                })
            }
        },
    }
}
</script>