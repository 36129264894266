<template>
  <div style=" text-align: center;">
    <section id="index" class="container">

      <div id="videoaprocessdiv" :style="{ display: videoaprocessdivVisible }">

        <p>&nbsp;</p>

        <div :style="{ display: uploadFormVisible }">

          <el-upload class="upload-demo" :action="upurl" :http-request="handleHttpRequest" :multiple="false">
            <el-button class="upload-demo-btn" size="middle" type="primary">点击上传</el-button>
            <div>&nbsp;</div>
          </el-upload>
          <video style="height:auto;width: 1100px;" src="../../../public/static/video/qushuiyin.mp4" id="videoctrl"
            controls="controls"></video>
        </div>
        
        <div id="videoDiv" :style="{ display: videoVisible }">
          <div>
            <div id="drawctrlDiv" :style="{ display: drawctrlDivVisible }">
              <el-button type="primary" class="upload-demo-btn">画框</el-button>
              <el-button type="warning" @click="revert" class="upload-demo-btn">撤销</el-button>
              <el-button type="danger" @click="resetAll" class="upload-demo-btn">清空</el-button>
            </div>
            <br />
            <div style="position:relative;overflow: auto;">
              <div :style="{ textAlign: videoalign }" style="border: 1px;">
                <div id="canvasContent" style="position: absolute;z-index: 999;"></div>
                <div>
                  <table style="width:100%">
                    <tr>
                      <td style="width:70%">
                        <video src="" id="videoctrl2" controls="controls"></video>
                      </td>
                      <td style="text-align:center;vertical-align:middle;border: aquamarine;border-width: 1px;">
                        <div style="min-width: 250px; text-align: center; vertical-align: middle;">
                          <h1 style="text-align:center">操作说明</h1>
                          <br />
                          <h2>&nbsp;&nbsp;1、使用画框工具在视频中框选出需要擦除的部分</h2>
                          &nbsp;
                          <h2>&nbsp;&nbsp;2、点击撤销可撤销上一个绘制的选择框</h2>
                          &nbsp;
                          <h2>&nbsp;&nbsp;3、点击清空可清空所有绘制的选择框</h2>
                        </div>
                      </td>
                    </tr>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
        <p></p>

        <p></p>
        <p>&nbsp;</p>
        <div id="nextB" :style="{ display: nextVisible }">
          <el-button type="primary" @click="dialogFormVisible = true;" class="upload-demo-btn">下一步</el-button>
        </div>
        <p>&nbsp;</p>


        <el-dialog title="支付方式" :visible.sync="dialogFormVisible" width="30%" style="text-align:left">

          <el-row>
            <el-col :span="12">
              <div>时长：{{ videoTime }}</div>
            </el-col>
            <el-col :span="12">
              <div>价格：￥{{ totalPrice / 100 }}金币 </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div>计价规则：￥{{ unitPrice / 100 }}金币/秒，不足一秒按一秒计算，超过按实际时长计算</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="width:100%">
              <el-radio style="width:100%" v-model="radioPayType" label="1" border>
                金币支付&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可用金币：{{ balance / 100 }}金币</el-radio>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="width:100%">
              <el-radio style="width:100%" v-model="radioPayType" disabled label="2" border>
                微信支付&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;总计价格：￥{{ totalPrice / 100 }}金币</el-radio>
            </el-col>
          </el-row>

          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogFormVisible = false; donext()">确认支付</el-button>
          </div>
        </el-dialog>



      </div>
    </section>
  </div>
</template>
  
<script>
import request from '@/utils/request'
import userApi from "../../api/user";
import projectAPi from '../../api/project';
export default {
  data() {
    return {
      activeName: 'first',
      billDate: '',
      dialogFormVisible: false,
      typeDialogFormVisible: false,
      uploadFormVisible: "block",
      videoVisible: "none",
      nextVisible: "none",
      radioPayType: '1',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      formLabelWidth: '120px',
      balance: '',
      videoTime: '',
      unitPrice: '',
      proType: '视频去水印_字幕_台标',
      totalPrice: 0,
      projectID: '',
      projectdivVisible: "block",
      videoaprocessdivVisible: "block",
      drawctrlDivVisible: "none",
      upurl: 'https://www.swdl.net.cn:8090/api/test/upload',
      canvas: "",
      ctx: "",
      ctxX: 0,
      ctxY: 0,
      lineWidth: 2,
      type: "R",
      canvasHistory: [],
      lineHistory: [],
      step: 0,
      loading: false,
      fillStyle: "#CB0707",
      strokeStyle: "#CB0707",
      img: new Image(),
      videoalign: "left",
      videoLeft: 0,
      videoTop: 0
    }
  },

  //页面加载时执行
  created() {
    var userName = localStorage.getItem("userName");
    if (userName == "" || userName == null) {
      // this.$router.push({ path: "/login" });
    }
    else {
      this.queryUserInfo()
      this.projectdivVisible = "none";
      // this.videoaprocessdivVisible = "block";
      this.drawctrlDivVisible = "block";
      this.videoalign = "left";

    }

  },

  methods: {
    getObjectURL(file) {
      var url = null;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        let binaryData = [];
        binaryData.push(file);
        url = window.URL.createObjectURL(new Blob(binaryData));
      } else if (window.webkitURL != undefined) {
        let binaryData = [];
        binaryData.push(file);
        url = window.URL.createObjectURL(new Blob(binaryData));
      }
      return url;
    },
    videoShow(file) {
      document.getElementById("videoctrl2").src = this.getObjectURL(file.file);
      setTimeout(this.ShowCanvas, "1000");
    },
    handleHttpRequest(file) {
      var userName = localStorage.getItem("userName");
      if (userName == "" || userName == null) {
        this.$showWarning("您还没有登录，请登录后再使用")
      }
      else {
        const formData = new FormData()
        formData.append('file', file.file)
        var userId = localStorage.getItem("userId")
        formData.append('projectType', this.proType)
        formData.append('userId', userId)
        request.post(this.upurl, formData).then(data => {
          this.videoTime = data.videoTime
          this.proType = data.proType
          this.unitPrice = data.unitPrice
          this.totalPrice = data.totalPrice
          this.projectID = data.proID
          this.videoShow(file)
          this.nextVisible = "block"
          this.videoVisible = "block"
          this.uploadFormVisible = "none"
        }).catch(response => {
          console.log(response)
        })
      }

    },
    donext() {
      if (this.balance < this.totalPrice) {
        this.$showWarning("余额不足，即将为您跳转到充值页面")
        this.timer = setTimeout(() => {
          this.$router.push({ path: "/User?tab=charge" });
        }, 1000);
      }
      else {
        projectAPi.payProject(this.projectID, this.lineHistory).then((response) => {
          if (response.code === 0) {
            // projectTypeApi.list().then((response) => {
            //   this.projectTypeList = response.data.projectTypeList;
            // });
            alert("项目创建成功，请到用户中心查看项目")
            this.timer = setTimeout(() => {
              this.$router.push({ path: "/User?tab=project" });
            }, 1000);
          }
          else {
            this.$showWarning("余额不足，即将为您跳转到充值页面")
            this.timer = setTimeout(() => {
              this.$router.push({ path: "/User" });
            }, 1000);
          }
        })
      }
    },

    queryUserInfo() {
      userApi.queryUserByID(localStorage.getItem("userId")).then((response) => {
        this.balance = response.data.userInfo.balance;
      })
    },
    toWorkSpace(projectType) {
      this.projectdivVisible = "none";
      this.videoaprocessdivVisible = "block";
      this.proType = projectType;
      this.drawctrlDivVisible = "block";
      this.videoalign = "left";
    },

    // 清空画布及历史记录
    resetAll() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.canvasHistory = [];
      this.lineHistory = [];
      this.ctx.drawImage(this.img, 0, 0);
      this.canvasHistory.push(this.canvas.toDataURL());
      this.step = 0;
    },
    // 清空当前画布
    reset() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.drawImage(this.img, 0, 0);
    },
    // 撤销方法
    revert() {
      if (this.step >= 1) {
        this.step = this.step - 1;
        let canvasPic = new Image();
        canvasPic.src = this.canvasHistory[this.step];
        this.lineHistory.length = this.lineHistory.length - 1;
        canvasPic.addEventListener("load", () => {
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.ctx.drawImage(canvasPic, 0, 0);
          this.loading = true;
        });
      } else {
        this.$message.warning("不能再继续撤销了");
      }
    },
    // 绘制历史数组中的最后一个
    rebroadcast() {
      let canvasPic = new Image();
      canvasPic.src = this.canvasHistory[this.step];
      canvasPic.addEventListener("load", () => {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.drawImage(canvasPic, 0, 0);
        this.loading = true;
      });
    },
    // 绑定事件,判断分支
    bindEventLisner() {
      let _this = this;
      let r1, r2; // 绘制圆形，矩形需要
      this.canvas.onmousedown = function (e) {
        r1 = e.layerX;
        r2 = e.layerY;
        _this.createRect(e, "begin", r1, r2);
      };
      this.canvas.onmouseup = function (e) {
        _this.createRect(e, "end", r1, r2);
        r1 = null;
        r2 = null;
      };

    },
    // 绘制矩形
    createRect(e, status, r1, r2) {
      let _this = this;
      if (status == "begin") {
        _this.canvas.onmousemove = function (e) {
          _this.reset();
          let rx = e.layerX - r1;
          let ry = e.layerY - r2;

          //保留之前绘画的图形
          if (_this.step !== 0) {
            let canvasPic = new Image();
            canvasPic.src = _this.canvasHistory[_this.step];
            _this.ctx.drawImage(canvasPic, 0, 0);
          }

          _this.ctx.beginPath();
          _this.ctx.strokeRect(r1, r2, rx, ry);
          _this.ctx.strokeStyle = _this.strokeStyle;
          _this.ctx.lineWidth = _this.lineWidth;
          _this.ctx.closePath();
          _this.ctx.stroke();
        };
      } else if (status == "end") {
        _this.rebroadcast();
        let interval = setInterval(() => {
          if (_this.loading) {
            clearInterval(interval);
            _this.loading = false;
          } else {
            return;
          }
          let rx = e.layerX - r1;
          let ry = e.layerY - r2;
          _this.ctx.beginPath();
          _this.ctx.rect(r1, r2, rx, ry);

          _this.ctx.strokeStyle = _this.strokeStyle;
          _this.ctx.lineWidth = _this.lineWidth;
          _this.ctx.closePath();
          _this.ctx.stroke();
          _this.step = _this.step + 1;
          if (_this.step < _this.canvasHistory.length - 1) {
            _this.canvasHistory.length = _this.step; // 截断数组
          }
          _this.canvasHistory.push(_this.canvas.toDataURL());
          if (r1 != "undefined" && r1 != undefined) {
            _this.lineHistory.push(r1 + "&" + r2 + "&" + rx + "&" + ry)
          }
          _this.canvas.onmousemove = null;
        }, 1);
      }
    },

    ShowCanvas() {
      let _this = this;
      let content = document.getElementById("canvasContent");
      content.style.cursor = "crosshair";
      _this.canvas = document.createElement("canvas");
      var video = document.getElementById("videoctrl2");

      video.height = video.videoHeight;
      video.width = video.videoWidth;
      _this.canvas.height = video.videoHeight;
      _this.canvas.width = video.videoWidth;
      _this.videoLeft = video.style.left;
      _this.videoTop = video.style.top;
      _this.ctx = _this.canvas.getContext("2d");
      _this.ctx.globalAlpha = 1;
      _this.canvasHistory.push(_this.canvas.toDataURL());
      _this.ctx.globalCompositeOperation = _this.type;
      video.play();
      content.appendChild(_this.canvas);
      _this.bindEventLisner();
    },
  }
}
</script>