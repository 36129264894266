const sliderData = [
    {
        url: require("../assets/img/bg1.webp"),
        description: "1",
        link: "1"
    },
    {
        url: require("../assets/img/bg2.webp"),
        description: "2",
        link: "2"
    }
]
const projectTypeList = [
    {
        "id": "1",
        "protype": "视频去水印_字幕_台标",
        "protypedesc": "该工具可以方便地去除不需要的元素，提升视频的质量和美观度。",
        "protypeprice": 1,
        "pic": "wrapper.png",
        "page": "video",
        "tab": "wrapper"
    },
    {
        "id": "2",
        "protype": "视频清晰度提升",
        "protypedesc": "如果您有一段模糊不清的视频，这个AI处理工具可以帮助您提升其清晰度。",
        "protypeprice": 2,
        "pic": "chaofen.png",
        "page": "video",
        "tab": "chaofen"
    },
    {
        "id": "3",
        "protype": "在线消除笔",
        "protypedesc": "该工具可以轻松擦除图片当中您不想展示的元素，而不影响整体观感。",
        "protypeprice": 3,
        "pic": "rep.png",
        "page": "pic",
        "tab": "removepen"
    },
    {
        "id": "7",
        "protype": "在线人像抠图",
        "protypedesc": "上传包含人物的图片，自动识别人物并与背景分离，方便您在设计、摄影等方面使用。",
        "protypeprice": 4,
        "pic": "personbgremove.png",
        "page": "pic",
        "tab": "personMatting"
    },
    {
        "id": "8",
        "protype": "自制证件照",
        "protypedesc": "不想专门去照相馆拍证件照？只需上传一张普通照片，自动生成符合证件照要求的照片。",
        "protypeprice": 5,
        "pic": "idimage.jpg",
        "page": "pic",
        "tab": "faceMatting"
    }
]

const introduceData = [
    {
        title: 'AI视频',
        text: '无论您是想制作宣传片、广告、教育视频还是其他类型的视频内容，我们都能为您提供高质量的产品。让我们一起创造出令人难忘的视频体验！',
        type: '视频',
        url:'https://www.swdl.net.cn/#/VResourceSearch?radioVideoScene=%E5%85%A8%E9%83%A8&resourceType=%E8%A7%86%E9%A2%91&searchContent='
    },
    {
        title: 'AI音频',
        text: '无论您是个人用户还是企业客户，我们都能根据您的需求量身定制音频内容，帮助您实现您的目标。选择AIGC音频，让我们一起打造出独特而引人入胜的音频作品！',
        type: '音频',
        url:'https://www.swdl.net.cn/#/VResourceSearch?radioAudioScene=%E5%85%A8%E9%83%A8&resourceType=%E9%9F%B3%E9%A2%91&searchContent='
    },
    {
        title: 'AI图片',
        text: '还在为了找各种图像素材发愁吗，选择我们就对了。我们提供了海量的有AI自动生成的图像资源，您也无需担心版权问题，仅需说出您的需求，剩下的有我们的AI来处理！',
        type: '图片',
        url:'https://www.swdl.net.cn/#/VResourceSearch?radioPicScene=%E5%85%A8%E9%83%A8&resourceType=%E5%9B%BE%E7%89%87&searchContent='
    }
]
const introSlider = [
    {
        id: '1',
        alt: '市场营销',
        imgsrc: 'https://qncdn.aoscdn.com/astro/picwish/_astro/index-why-swipers-2@3600w.ea40c603.png',
    },
    {
        id: '2',
        alt: '电商专用',
        imgsrc: 'https://qncdn.aoscdn.com/astro/picwish/_astro/index-why-swipers-1@3600w.de573ddf.png'
    },
    {
        id: '3',
        alt: '平面设计',
        imgsrc: 'https://qncdn.aoscdn.com/astro/picwish/_astro/index-why-swipers-2@3600w.ea40c603.png'
    },
]
const cardData = [
    {
        title: '视频素材',
        text: '海量视频素材供您下载，实拍视频、珍贵史料、经典电影、经典动漫等',
        url:'VResourceSearch?radioVideoScene=%E5%85%A8%E9%83%A8&resourceType=%E8%A7%86%E9%A2%91&searchContent='
    },
    {
        title: '图片素材',
        text: '餐饮美食、城市建筑、动物世界、商务办公、自然风景，应有尽有',
        url:'VResourceSearch?radioPicScene=%E5%85%A8%E9%83%A8&resourceType=%E5%9B%BE%E7%89%87&searchContent='
    },
    {
        title: '音频素材',
        text: '各种自然声音、影视配乐、动物声音等音频素材，可直接在线试听、在线下载',
        url:'VResourceSearch?radioAudioScene=%E5%85%A8%E9%83%A8&resourceType=%E9%9F%B3%E9%A2%91&searchContent='
    },
    {
        title: 'AI图像创意',
        text: '您只需要输入一段文字，我们自动为您生成高质量图片',
        url:'AIPic'
    },
    {
        title: '在线抠图',
        text: '人像自动抠图、任意设备手动剔除，您只需要上传一张照片，剩下的交给我们',
        url:'AIImage?tab=personMatting'
    },
    {
        title: '证件照制作',
        text: '无需繁琐的PS操作步骤，您的任意一张生活照，我们都能为您制作出各种尺寸的证件照',
        url:'AIImage?tab=faceMatting'
    }
]
const commentData = [
    {
        id: '1',
        userName: '会飞的鱼',
        avatar: 'https://qncdn.aoscdn.com/astro/picwish/_astro/index-comment-zh-5@240w.f6ef18c2.png',
        text: '评语：这个网站上的功能太好用了，终于找到一个宝藏网站，不仅能下载资源，还能在线生成你想要的资源，简直不要太完美',
        source: 'https://qncdn.aoscdn.com/astro/picwish/_astro/bilib.c959fd32.svg',
        link: 'https://fanyi.baidu.com/'
    },
    {
        id: '2',
        userName: '欢欢',
        avatar: 'https://qncdn.aoscdn.com/astro/picwish/_astro/index-ai-art-bg@600w.4ca9ba5a.png',
        text: '评语：终于找到了一个功能一应俱全的网站，无论是下载资源还是在线生成，都能满足我的需求，太赞了！',
        source: 'https://qncdn.aoscdn.com/astro/picwish/_astro/weibo.893eaf80.svg',
        link: 'https://fanyi.baidu.com/'
    },
    {
        id: '3',
        userName: '小五',
        avatar: 'https://qncdn.aoscdn.com/astro/picwish/_astro/index-white-bg@600w.42991315.png',
        text: '评语：这个网站可以说是我找了很久的“神器”了！下载资源方便，而且还能在线生成各种资源，简直太完美了！',
        source: 'https://qncdn.aoscdn.com/astro/picwish/_astro/redBook.1b11483b.svg',
        link: 'https://fanyi.baidu.com/'
    },
    {
        id: '4',
        userName: '欢欢',
        avatar: 'https://qncdn.aoscdn.com/astro/picwish/_astro/index-why-swipers-3@3600w.74b2f17c.png',
        text: '评语：这个网站真的是我所见过的最好用的！下载资源快速便捷，生成资源多样丰富，太完美了！',
        source: 'https://qncdn.aoscdn.com/astro/picwish/_astro/redBook.1b11483b.svg',
        link: 'https://fanyi.baidu.com/'
    },
    {
        id: '5',
        userName: '涛涛',
        avatar: 'https://qncdn.aoscdn.com/astro/picwish/_astro/index-why-swipers-3@3600w.74b2f17c.png',
        text: '评语：经过多次寻找，终于找到了这个网站，不仅可以下载资源，还能在线生成，真的是太实用了！',
        source: 'https://qncdn.aoscdn.com/astro/picwish/_astro/tiktok.8fb9f2a1.svg',
        link: 'https://fanyi.baidu.com/'
    },
    {
        id: '6',
        userName: '刘洋',
        avatar: 'https://qncdn.aoscdn.com/astro/picwish/_astro/index-why-swipers-3@3600w.74b2f17c.png',
        text: '评语：这个网站可以说是我找了很久的“神器”了！下载资源方便，而且还能在线生成各种资源，简直太完美了！',
        source: 'https://qncdn.aoscdn.com/astro/picwish/_astro/tiktok.8fb9f2a1.svg',
        link: 'https://fanyi.baidu.com/'
    }
]

export {
    sliderData, projectTypeList, introduceData, introSlider, cardData, commentData
}