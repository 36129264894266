import request from '@/utils/request'

export default{

  //查询项目列表
  list(userId) {
    return request({
      url: '/api/project/listByUser/'+userId,
      method: 'post'
    })
  },

  queryVideoTime()
  {
    
  },

  getProject(projectID){
    return request({
      url: '/api/project/getProjectByID/'+projectID,
      method: 'post'
    })
  },

  payProject(projectID,rectInfo){
    if(rectInfo==""||rectInfo==null)
    {
      rectInfo="null";
    }
    return request({
      url: '/api/project/payProjectByID/'+projectID+'/'+rectInfo,
      method: 'post'
    })
  },

  payProject2(projectID,rectInfo){
    if(rectInfo==""||rectInfo==null)
    {
      rectInfo="null";
    }
    return request({
      url: '/api/project/payProjectByID/'+projectID+'/'+rectInfo,
      method: 'post'
    })
  },
  
}